import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AssessmentCrud, AssessmentData } from './hooks'
import { Words } from 'components'

const Assessment = () => {
    const { words } = useSelector(state => state.words)
    const { assessment } = useSelector(state => state.assessment)
    const { init, cleanData } = AssessmentCrud()

    const { assessment_def_visible_type, assessment_def_name, points } = assessment || {}

    useEffect(() => {
        init()

        return (() => {
            cleanData()
        })
    }, [assessment?.id])

    return (
        <Words
            headerActions={[]}
            type={assessment_def_visible_type}
            name={assessment_def_name}
            words={words}
            points={points}
            hidePoints={false}
            hideBackBtn={true}
            showErrorTypes={true}
            settings={{
                viewResults: true
            }}
        />
    )
}

export default Assessment