
import { React, useEffect, useRef, useState } from 'react'
import { DownloadRounded, Pause, PlayArrowSharp, PlayCircleFilled } from '@mui/icons-material'
import { useSelector } from 'react-redux';
import { WrapRecord, AudioTitle, ChangeAudioSetting, WrapActions, WrapWavesurferPlayer, BasicRecord, Action } from './Style'
import { secondary, thirdly } from 'styles/theme';
import { RecordCrud, RecordData } from './hooks';
import { Progress, ChangeSetting, surferHooks } from 'components/Record'

import './App.css'
import { useTheme } from 'styles';
import { assign } from 'lodash';
import { wavesurferToTime } from 'utils/formatter';
import { actions } from 'store';
import { useDispatch } from 'react-redux';
import { Label } from 'components/Form';

const Record = (props) => {
    const { autoplay, stopAudioAfterSeconds, showTimeline, height = 50, id = "basic-record", hidden, changeSetting } = props

    const [isReady, setIsReady] = useState(false)

    const { Layout } = useTheme()
    const dispatch = useDispatch()
    const pathAudioRef = useRef(null);

    const { assessment } = useSelector(state => state.assessment)
    const { isPlaying, rate, zoomIn, wavesurfer } = useSelector(state => state.wavesurfer)

    const onPlayPause = surferHooks.useOnPlayPause()
    const onPause = surferHooks.useOnPause()
    const downloadAudio = surferHooks.useDownload()
    const onMount = surferHooks.useOnMount()
    const plugins = surferHooks.usePlugins()


    useEffect(() => {
        let isSeekingManually = false;

        // Add event listener for spacebar key press
        const handleKeyPress = (event) => {
            switch (event.code) {
                case 'Space':
                    onPlayPause();
                    event.preventDefault()
                    break
                case 'ArrowLeft':
                    wavesurfer.skip(-2)
                    event.preventDefault()
                    break
                case 'ArrowRight':
                    wavesurfer.skip(2)
                    event.preventDefault()
                    break
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        // We removed the delay from the audio skip time Because it gave us problems when we used the 2 second forward and back buttons and it caused the audio to stop which is not the desired behavior

        // wavesurfer?.on('seeking', () => {
        //     isSeekingManually = true;
        //     onPause()
        //     setTimeout(() => {
        //         isSeekingManually = false;
        //     }, 100); // Adjust the timeout as needed to consider the duration of a manual seek
        // });

        wavesurfer?.on('ready', () => {
            setIsReady(true)
        });

        wavesurfer?.on('finish', () => {
            onPause()
        });

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [isPlaying, wavesurfer, zoomIn, rate]);

    useEffect(() => {
        return () => {
            onPause()
            dispatch(actions.setWavesurfer(null))
        }
    }, [])

    return (
        <WrapRecord $hidden={hidden} $basic={true} $changeSetting={changeSetting}>
            <AudioTitle style={{ ...Layout.shrink, ...Layout.flex, ...Layout.scGap }}>
                {assessment.id && <Label permissionHOC="hidden" role="read" feature="assessment-id" bold label={`מבדק ${assessment.id}`} fontSize={16} />}
                {assessment.reading_assessment_name}
            </AudioTitle>
            <div style={{ ...Layout.grow }}>
                {assessment.audio_file ?
                    <>
                        {!isReady && <Progress />}
                        {isReady && changeSetting && <ChangeSetting />}
                        <ChangeAudioSetting>
                            {isReady &&
                                <div style={assign(Layout.rowCenter, Layout.scGap)}>

                                    <Action onClick={downloadAudio}>
                                        {<DownloadRounded />}
                                    </Action>
                                    {wavesurferToTime(wavesurfer?.getDuration())}
                                </div>
                            }
                            <BasicRecord $hidden={!isReady} id={id} className='basic-record' />

                            {isReady &&
                                <Action color='gray' onClick={onPlayPause}>
                                    {isPlaying ? <Pause /> : <PlayArrowSharp />}
                                </Action>
                            }

                        </ChangeAudioSetting>

                        <WrapWavesurferPlayer
                            container={`#${id}`}
                            autoplay={autoplay}
                            minPxPerSec={zoomIn * 10}
                            height={height}
                            plugins={showTimeline && plugins}
                            audioRate={rate}
                            normalize={true}
                            progressColor={thirdly}
                            waveColor={"#bfbfc5"}
                            url={assessment.audio_file}
                            key={pathAudioRef.current}
                            rtl={true}
                            onMount={(surfer) => onMount(surfer, stopAudioAfterSeconds)}
                        />
                    </>
                    :
                    <Progress />
                }
            </div>
        </WrapRecord >
    )
}

export default Record
