import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AssessmentCrud, AssessmentData } from './hooks'
import { componentKeys } from 'keys/components'
import { Page, Words } from 'components'

const Assessment = () => {
    const { words } = useSelector(state => state.words)
    const { assessment } = useSelector(state => state.assessment)
    const { init, cleanData } = AssessmentCrud()
    const { headerActions } = AssessmentData()

    const { assessment_def_visible_type, assessment_def_name, points } = assessment || {}
    const { assessmentHeader } = componentKeys

    useEffect(() => {
        init()

        return (() => {
            cleanData()
        })
    }, [assessment?.id])

    return (
        <Page>
            <Words
                headerActions={headerActions}
                header={assessmentHeader}
                type={assessment_def_visible_type}
                name={assessment_def_name}
                words={words}
                points={points}
                hidePoints={false}
                hideBackBtn={true}
                showErrorTypes={true}
                settings={{
                    viewResults: true
                }}
            />
        </Page>
    )
}

export default Assessment