import React, { useState } from 'react'
import { Label } from 'components/Form'
import { WrapUploadFileButton, Label as Text, WrapHeaderUpload, WrapAudioContent, WrapFieldContent, WrapField, VisuallyHiddenInput, WrapAudio } from './Styled'
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Error from './Error'

const UploadFile = (props) => {
    const { name, label, input = {}, cleanAudio, section, className, mandatory } = props,
        { onChange, value } = input;

    const handleChange = e => {
        const file = e.target.files[0];
        onChange(file)
    };

    const cleanFile = () => {
        onChange(null)
    }

    return (
        <WrapField className={className} section={section}>
            <WrapFieldContent>
                {label && <Label label={label} mandatory={mandatory} />}

                {value &&
                    <WrapHeaderUpload>
                        {/* {label && <Label label={label || "העלאת קובץ אודיו"} mandatory={mandatory} />} */}
                        <Text>{value?.name}</Text>
                        {cleanAudio && <IconButton size='small' onClick={cleanFile}>
                            <Close fontSize='small' />
                        </IconButton>}
                    </WrapHeaderUpload>
                }
                <WrapAudioContent>
                    {value ?
                        <WrapAudio controls>
                            <source src={URL.createObjectURL(value)} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </WrapAudio>
                        :
                        <WrapUploadFileButton
                            color="primary"
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                        >
                            <VisuallyHiddenInput
                                type="file"
                                name={name}
                                onChange={handleChange}
                                accept="audio/*"
                            />
                            להקליק לבחירת קובץ
                        </WrapUploadFileButton>
                    }
                </WrapAudioContent>
            </WrapFieldContent>
            <Error {...props} />
        </WrapField>
    )
}

export default UploadFile