import { statuses, updateType } from 'keys';
import { correct, incorrect, insertion, minorError, omission, repetition, selfCorrection } from 'keys/types';
import { findIndex, isEmpty, keyBy } from 'lodash'
import update_type from 'mock/updateType';

class AssessmentRM {
    constructor(assessmentData) {
        const { end, timePoint = {}, words } = assessmentData

        let timePointIndex = -1
        if (timePoint)
            timePointIndex = findIndex(words, { 'word_def': timePoint.word_def_id });

        let endIndex = -1
        if (end) {
            endIndex = findIndex(words, { 'word_def': end });
        }
        if (endIndex === -1)
            endIndex = words.length - 1
        this.time_point = timePoint?.value

        let count = 0
        let errorsCount = 0
        let correctCount = 0
        let correctWordsInTimePoint = 0
        let selfCorrectionAndMinorError = 0
        let omissionAndMinorError = 0
        let omissionAndError = 0

        let customErrorsCount = {
            [repetition]: 0,
            [selfCorrection]: 0,
            [minorError]: 0,
            [omission]: 0,
            [insertion]: 0,
            ["omissionInTimePoint"]: 0
        }

        for (let index = 0; index < words?.length; index++) {
            if (index <= endIndex) {
                let word = words[index]
                const { errors, status } = word
                let nErrors = errors.filter((error) => error.update_type != update_type.delete)
                nErrors.forEach(error => {
                    if (error.status === omission && index <= timePointIndex)
                        customErrorsCount.omissionInTimePoint++
                    customErrorsCount[error.status]++
                });
                let errorKeys = keyBy(nErrors, "status")

                //self correction as minor error count only in selfCorrectionAndMinorError
                if (errorKeys[minorError] && errorKeys[selfCorrection]) {
                    selfCorrectionAndMinorError += 1

                    customErrorsCount[minorError]--
                    customErrorsCount[selfCorrection]--
                }
                //omission as error and omission as minor error count only in omissionAndMinorError/omissionAndError
                if (status == incorrect && errorKeys[omission]) {
                    customErrorsCount[omission]--

                    if (errorKeys[minorError])
                        omissionAndMinorError += 1

                    else omissionAndError += 1
                }

                if (status === incorrect && word.update_type != update_type.delete)
                    errorsCount++

                if (index <= timePointIndex && word.status === correct)
                    correctWordsInTimePoint++

                if (index <= endIndex && word.status === correct)
                    correctCount++

            }
        }

        this.repetition_count = customErrorsCount.REPETITION
        this.self_correction_count = customErrorsCount.SELF_CORRECTION
        this.errors_count = errorsCount
        this.omission_count = customErrorsCount.OMISSION
        this.minor_error_count = customErrorsCount.MINOR_ERROR
        this.self_correction_and_minor_error_count = selfCorrectionAndMinorError
        this.insertion_count = customErrorsCount.INSERTION
        this.correct_words_count = correctCount
        this.omission_and_error = omissionAndError
        this.omission_and_minor_error = omissionAndMinorError

        if (endIndex)
            this.words_count = endIndex + 1 - customErrorsCount.OMISSION

        else
            this.words_count = words?.length - customErrorsCount.OMISSION


        if (!isEmpty(timePoint)) {
            this.words_in_time_point = timePointIndex + 1 - customErrorsCount.omissionInTimePoint
            this.correct_words_in_time_point = correctWordsInTimePoint
        }
    }
}

export default AssessmentRM