import { useMutation, useQuery, useQueryClient } from "react-query"
import { queryClient as qc } from 'keys'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"
import { useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import { getGradesFetch, getTClassesFetch, getTestTakerFetch, getTestTakersFetch, getTestTakersOptionsFetch, getWithLastAssessmentsFetch, getAllGradesFetch } from "store/middlewares/testTakers.crud"
import { convertSelectToValue } from "utils/formatter"
import { getAffiliationsFetch } from "store/middlewares/affiliation.crud"

//get test_taker by ID
const useGetTestTaker = () => {
    const dispatch = useDispatch()
    const { testTaker } = useSelector(state => state.testTaker)
    const params = useParams()
    const testTakerID = isEmpty(testTaker) ? params.testTakerID : testTaker.test_taker_id

    return useQuery(
        {
            queryKey: qc.testTaker,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            refetchOnMount: false,
            refetchInterval: false,
            queryFn: ({ signal }) => getTestTakerFetch({ id: testTakerID, signal, }),
            onSuccess: (res) => {
                if (res) {
                    const testTaker = res.data
                    dispatch(actions.setTestTaker(testTaker))
                }
            }
        })
}

//get classes by params
const useGetTClassess = (formValues) => {
    const dispatch = useDispatch()
    const { headerFilters } = useSelector(state => state.header)
    let group, affiliation, grade

    const filterValues = formValues || headerFilters

    if (filterValues) {
        group = convertSelectToValue(filterValues.group)
        affiliation = convertSelectToValue(filterValues.affiliation)
        grade = convertSelectToValue(filterValues.grade)
    }

    return useQuery({
        queryKey: qc.tClassess,
        queryFn: () => getTClassesFetch(
            convertSelectToValue(filterValues)
        ),
        enabled: false
    })
}

//get affiliation by params
const useGetAffiliations = (formValues) => {
    const dispatch = useDispatch()
    const { headerFilters } = useSelector(state => state.header)
    let group, affiliation

    const filterValues = formValues || headerFilters

    if (filterValues) {
        group = convertSelectToValue(filterValues.group)
        affiliation = convertSelectToValue(filterValues.affiliation)
    }

    return useQuery({
        queryKey: qc.tClassess,
        queryFn: () => getAffiliationsFetch(
            convertSelectToValue(filterValues)
        ),
        enabled: false,
        onSuccess: (res) => {
            dispatch(actions.setAffiliations(res.data))
        }
    })
}

//get grades by params
const useGetGrades = ({ enabled, formValues }) => {
    const { headerFilters } = useSelector(state => state.header)
    let group, affiliation

    const filterValues = formValues || headerFilters

    if (filterValues) {
        group = convertSelectToValue(filterValues.group)
        affiliation = convertSelectToValue(filterValues.affiliation)
    }

    return useQuery({
        queryKey: qc.grades,
        queryFn: () => getGradesFetch(
            convertSelectToValue(filterValues)
        ),
        refetchOnWindowFocus: false,
        enabled: enabled ? enabled : false,
        retry: 0,
        onSuccess: (res) => {
            return res.data
        }
    })
}

const useGetAllGrades = () => {
    return useQuery({
        queryKey: qc.allGrades,
        queryFn: async () => {
            const { data } = await getAllGradesFetch()
            return data
        },
        refetchOnWindowFocus: false,
        retry: 0
    })
}

//get test_takers by params
const useGetTestTakersOptions = (formValues) => {
    const dispatch = useDispatch()
    const { headerFilters } = useSelector(state => state.header)

    const filterValues = formValues || headerFilters

    return useQuery({
        queryKey: qc.testTakers,
        queryFn: () => getTestTakersOptionsFetch(
            convertSelectToValue(filterValues)
        ),
        enabled: false,
        onSuccess: (res) => {
            dispatch(actions.setTestTakers(res.data))
        }
    })
}

//get test_takers 
const useGetTestTakers = () => {
    return useQuery({
        queryKey: qc.testTakers,
        queryFn: getTestTakersFetch,
        enabled: false
    })
}

const useUpdateOrCreateChildren = () => {

    return useMutation({
        // mutationFn: updateOrCreateChildrenFetch
    })
}

const useGetWithLastAssessments = () => {
    const { headerFilters } = useSelector(state => state.header)

    return useQuery(
        {
            queryKey: qc.getWithLastAssessments,
            queryFn: ({ signal }) => getWithLastAssessmentsFetch({ signal, headerFilters }),
            retry: 0
        })
}

export default { useGetAllGrades, useUpdateOrCreateChildren, useGetTestTakers, useGetAffiliations, useGetGrades, useGetWithLastAssessments, useGetTestTaker, useGetTClassess, useGetTestTakersOptions }