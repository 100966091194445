import { useDispatch, useSelector } from "react-redux"
import { change, initialize, isValid, submit } from "redux-form"
import { application, customUserRQ, testTakerRQ } from "store/hooks"
import { useMutation, useQueryClient } from "react-query"
import { updateOrCreateTestTakerFetch } from "store/middlewares/testTakers.crud"
import { TestTakerRM } from "models/RM"
import { secretStorage } from "config"
import { CreateAssessmentBtn } from "components/School/CreateAssessment"
import { queryClient as qc } from "keys"

const useSubmitBtns = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const setAppData = application.useInitAppData()

    const { mutateAsync: updateOrCreateTestTaker, isLoading: createTestTakerLoading } = useMutation({
        mutationFn: updateOrCreateTestTakerFetch,
        onSuccess: (res) => {
            const { data } = res
            const { user_data, test_taker } = data

            const user = secretStorage.getItem("user")
            let nUser = { ...user, user_data: user_data }
            secretStorage.setItem("user", nUser)
            setAppData(nUser)

            dispatch(initialize("test_taker", formValues))

            dispatch(change("test_taker", "test_taker_id", test_taker.test_taker_id))
            dispatch(change("test_taker", "balance", test_taker.balance))
            queryClient.invalidateQueries({ queryKey: qc.getWithLastAssessments })
            queryClient.invalidateQueries({ queryKey: qc.dashboard })
        }
    })

    const { mutateAsync: validateTz } = customUserRQ.useValidateTz()

    const formValues = useSelector(state => state.form["test_taker"]?.values)
    const initialValues = useSelector(state => state.form["test_taker"]?.initial)
    const isFormValid = useSelector(state => isValid("test_taker")(state));

    const checkValidFromChild = async () => {
        let isValid = await validateTz(formValues?.tz)

        if (isFormValid && formValues != undefined && isValid)
            return true
        return false
    }

    const updateOrCreate = async () => {
        let isValid = true
        if (!isFormValid)
            isValid = false
        else if (formValues.tz != initialValues?.tz) {
            isValid = await checkValidFromChild()
        }

        if (isValid) {
            const test_taker = new TestTakerRM(formValues)
            const testTakerRes = updateOrCreateTestTaker(test_taker)
        }
        else {
            dispatch(submit("test_taker"))
        }
    }

    const submitBtns = [
        {
            id: 0,
            label: "שמירה",
            action: updateOrCreate,
            color: "thirdly",
            loading: createTestTakerLoading,
        },
        {
            component: "custom",
            customComponent: CreateAssessmentBtn,
            disabled: !formValues?.test_taker_id,
            test_taker_id: formValues?.test_taker_id,
        }
    ]

    return submitBtns
}

export default useSubmitBtns