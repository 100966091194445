import React, { useContext } from 'react'
import ParagraphContent from './ParagraphContent'
import { partition } from 'lodash'
import { WordsContext } from './Words'

const Paragraph = (props) => {
    const { words, settings } = useContext(WordsContext)

    const [title, body] = partition(words, word => word.word_def_fe_details?.is_title);

    return (
        <>
            <ParagraphContent words={title} title settings={settings} startIndex={0} />
            <ParagraphContent words={body} settings={settings} startIndex={title.length - 1} />
        </>
    )
}

export default Paragraph