import { useSelector } from "react-redux"
import { MarkingWordsCrud } from "."
import { statuses, queryClient as qc, alerts as alertsKeys } from "keys"
import { useCallback } from "react"
import { AlertCrud } from "components/Alerts/hooks"
import { surferHooks } from "components/Record"

const useHeaderActions = () => {
    const { setAlerts } = AlertCrud()

    const { isPlaying } = useSelector(state => state.wavesurfer)
    const onPause = surferHooks.useOnPause()

    const { assessment } = useSelector(state => state.assessment)

    const {
        updateBrokenFileStatus, updateBrokenFileStatusLoading
    } = MarkingWordsCrud()

    const openInitializeConfirmation = useCallback(() => {
        onPause()
        setAlerts(alertsKeys.initializeAssessment, true)
    }, [isPlaying])

    const brokenFile = {
        [statuses.inProgress]: {
            [statuses.wordsInProgress]: {

            },
            [statuses.wordsComplete]: {
            }
        },
        [statuses.uploaded]: {
            "default": {
            }
        },
        [statuses.backToMarking]: {
            "default": {
            }
        },
        [statuses.brokenFile]: {
            "default": {
            }
        }
    }

    const brokenFileProps = brokenFile[assessment.status]?.[assessment.sub_status || "default"]

    const headerActions = [
        {
            id: 1,
            label: "איפוס",
            color: "rating3",
            action: openInitializeConfirmation,
            width: 80,
            component: 'button',
        },
        {
            label: "אודיו לא תקין",
            color: "dark",
            action: updateBrokenFileStatus,
            loading: updateBrokenFileStatusLoading,
            width: 110,
            component: 'button',
            disabled: assessment?.sub_status != statuses.wordsInProgress,
            hide: brokenFileProps?.hide,
            disabledColor: "gray2"
        }
    ]

    return headerActions
}

export default useHeaderActions