import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'store'
import { useLocation, useNavigate } from 'react-router-dom'
import { alerts as alertsKeys, dataGridModel, loading, statuses } from 'keys'
import { adminAssessmentsRQ, StoreCrud } from 'store/hooks'
import { useMutation, useQueryClient } from 'react-query'
import { backToAudioCuttingFetch, backToMarkingWordsFetch, backToTranscriptionFetch, continueMarkingWordsFetch, startMarkingWordsFetch } from 'store/middlewares/markingWords.crud'
import { useCallback } from 'react'
import { app, manage } from 'components/Router/routes'

const AdminAssessmentsDrud = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)
    const { updateAssessmentStatus } = StoreCrud()

    const { state } = useLocation()

    const { mutateAsync: backToMarkingWordsRQ, isLoading: backToMarkingWordsLoading } = useMutation({ mutationFn: backToMarkingWordsFetch, mutationKey: "backToMarkingWords" })
    const { mutateAsync: backToAudioCuttingRQ, isLoading: backToAudioCuttingLoading } = useMutation(backToAudioCuttingFetch)
    const { mutateAsync: backToTranscriptionRQ, isLoading: backToTranscriptionLoading } = useMutation(backToTranscriptionFetch)

    const { refetch: refetchAdminAssessments, isFetching: adminAssessmentsLoading } = adminAssessmentsRQ.useGetAdminAssessments({ enabled: false })

    const updateFilters = ({ status, sub_status }) => {
        dispatch(actions.cleanAssessments(null))
        dispatch(actions.updateStatusFilters({ status: status, sub_status: sub_status }))
    }

    const cleanData = () => {
        dispatch(actions.cleanStatusFilters())
        dispatch(actions.cleanAssessments(null))
        dispatch(actions.cleanAssessmentFilters())
        dispatch(actions.cleanSelectedAssessments())
        dispatch(actions.cleanTestTaker())
    }

    const setRowSelectionModel = (newRowSelectionModel) => {
        dispatch(actions.setSelectedAssessments(newRowSelectionModel))
    }

    const selectAssessment = async (assessment) => {
        dispatch(actions.setAssessment(assessment))
        dispatch(actions.setTestTaker({ test_taker_id: assessment.test_taker_id }))
    }

    const setBackToConfirmation = useCallback(async (value, props) => {
        dispatch(actions.setAlerts({
            key: alertsKeys.backTo, value: {
                open: value,
                props: props,
                onClose: (props) => props.onClose(props)
            }
        }))
    }, [])

    const openBackToMarkingWordsConfirmation =
        // useCallback(
        (assessmentRow, refetchAfterBackTo) => {
            setBackToConfirmation(true, {
                title: "החזר לסימון",
                content: "האם התכוונת להחזיר את המבדק לסימון?",
                loading: backToMarkingWordsLoading,
                onConfirm: async () => {
                    await backToMarkingWords(assessmentRow)
                    if (refetchAfterBackTo)
                        await refetchAdminAssessments()
                    setBackToConfirmation(false)

                },
                onClose: () => {
                    setBackToConfirmation(false)
                }
            }
            )
        }
    // }, [adminAssessmentsLoading])

    const openBackToAudioCuttingConfirmation = useCallback(async (assessmentRow, refetchAfterBackTo) => {
        setBackToConfirmation(true, {
            title: "החזר לחיתוך",
            content: "האם התכוונת להחזיר את המבדק לחיתוך?",
            onConfirm: async () => {
                await backToAudioCutting(assessmentRow)
                setBackToConfirmation(false)
                refetchAfterBackTo && refetchAdminAssessments()
            },
            onClose: () => {
                setBackToConfirmation(false)
            }
        })
    }, [])

    const openBackToTranscriptionConfirmation = useCallback(async (assessmentRow, refetchAfterBackTo) => {
        setBackToConfirmation(true, {
            title: "החזר לתעתוק",
            content: "האם התכוונת להחזיר את המבדק לתעתוק?",
            onConfirm: async () => {
                await backToTranscription(assessmentRow)
                setBackToConfirmation(false)
                refetchAfterBackTo && refetchAdminAssessments()
            },
            onClose: () => {
                setBackToConfirmation(false)
            }
        })
    }, [])

    const backToMarkingWords =
        // useCallback(
        async (assessmentRow) => {
            const nAssessment = assessmentRow || assessment
            const res = await backToMarkingWordsRQ(nAssessment)
            dispatch(actions.updateAssessment(res.data))
        }
    // , [assessment, backToMarkingWordsLoading])

    const backToAudioCutting = useCallback(async (assessmentRow) => {
        const nAssessment = assessmentRow || assessment
        const res = await backToAudioCuttingRQ(nAssessment)
        dispatch(actions.updateAssessment(res.data))
    }, [assessment])

    const backToTranscription = useCallback(async (assessmentRow) => {
        const nAssessment = assessmentRow || assessment
        const res = await backToTranscriptionRQ(nAssessment)
        dispatch(actions.updateAssessment(res.data))
    }, [assessment])

    return {
        selectAssessment,
        setRowSelectionModel,
        updateFilters,
        cleanData,
        updateAssessmentStatus,
        backToMarkingWords,
        setBackToConfirmation,
        backToAudioCutting,
        refetchAdminAssessments,
        openBackToMarkingWordsConfirmation,
        openBackToAudioCuttingConfirmation,
        openBackToTranscriptionConfirmation,

        //rq params
        backToMarkingWordsLoading,
        backToAudioCuttingLoading,
        backToTranscriptionLoading
    }
}

export default AdminAssessmentsDrud