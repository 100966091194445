import React, { Children, cloneElement, isValidElement } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { Wrap, Title, WrapFooterBtns, Form, SubTitle } from './Styled'
import { Button, RenderField } from 'components/Form'
import { Space } from 'styles/GlobalStyle';

//Using redux from to create a generic form
const FormWrap = (props) => {
    const { rowGap, children, csswrap, handleSubmit, subTitle, initialValues, submit, title, fields, submitBtns } = props

    return (
        <Form onSubmit={handleSubmit(submit)}>
            {title &&
                <div>
                    <Title>
                        {title}
                    </Title>
                    {subTitle &&
                        <SubTitle>
                            {subTitle}
                        </SubTitle>
                    }
                    <Space />
                </div>
            }
            <Wrap rowGap={rowGap} csswrap={csswrap}>
                {
                    Children.map(children, child => {
                        if (isValidElement(child)) {
                            return cloneElement(child, { form: props })
                        }
                        return child
                    })
                    ||
                    fields.map((field, index) =>
                        <Field
                            {...field}
                            key={index}
                            dynamicComponent={field.component}
                            component={RenderField}
                        />
                    )
                }
            </Wrap>
            <WrapFooterBtns>
                {submitBtns?.map((btn, index) =>
                    <Button key={index} {...btn} />
                )}
            </WrapFooterBtns>
        </Form>
    )


}

const mapStateToProps = (state, ownProps) => {
    const { form, validations, submit, initialValues } = ownProps
    return {
        form: form,
        validate: validations,
        onSubmit: submit,
        initialValues: initialValues
    }
}

export default compose(
    connect(mapStateToProps),
    reduxForm({
        destroyOnUnmount: false,
        keepDirtyOnReinitialize: true,
        enableReinitialize: true
    })
)(FormWrap);
