

const useFields = () => {

    const fields = [
        {
            name: "email",
            component: "input",
            placeholder: "מייל",
            fullWidth: true,
            autocomplete:"email",
        },
        {
            name: "subject",
            component: "input",
            placeholder: "נושא",
            fullWidth: true
        },
        {
            name: "message",
            component: "input",
            placeholder: "תוכן",
            fullWidth: true
        },
       
    ]

    return fields
}

export default useFields