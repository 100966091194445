import styled, { css } from "styled-components"
import { Scroll } from "styles/GlobalCss"

const WrapTestTakerForm = styled.div`
._child__first_name{
grid-area:child__first_name;
}
._child__last_name{
grid-area:child__last_name;
}
._child__gender{
grid-area:child__gender;
}
._child__tz{
grid-area:child__tz;
}
._child__db{
grid-area:child__db;
}
._child__langauge{
grid-area:child__langauge;
}
._child__school{
grid-area:child__school;
}
._child__grade{
grid-area:child__grade;
}
._child__t_class{
grid-area:child__t_class;
}
`

const TestTakerForm = css`
display: grid;
grid-gap: 12px;
grid-template-columns: min-content;
grid-template-areas:
 "child__first_name child__first_name child__last_name child__last_name child__gender child__gender"
 "child__tz child__tz child__tz child__db child__db child__db"
 "child__school child__school child__school child__school child__school child__school" 
 "child__grade child__grade child__t_class child__t_class child__langauge child__langauge"
`

export { TestTakerForm, WrapTestTakerForm }