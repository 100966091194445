import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { WrapHeader, WrapPullDown, WrapContent } from './Style'
import { HeaderData } from 'components/Header/hooks'
import { fieldsTyps } from 'components/Form'
import { pulldowns, testTakerRQ } from 'store/hooks'
import { Can } from 'components/Permissions'
import { Divider } from '@mui/material'

const Header = () => {
    const { affiliations, groups } = useSelector(state => state.affiliation)
    const { grades, testTakers } = useSelector(state => state.testTaker)
    const { headerFilters } = useSelector(state => state.header)

    const { pulldowns: pulldownsData } = HeaderData()

    const setPulldownDefaultValue = pulldowns.useDefaultValue()
    const handleGroup = pulldowns.useHandleGroup()
    const handleGrade = pulldowns.useHandleGrade()
    const cleanTClassess = pulldowns.useCleanTClassess()
    const { refetch: refetchTestTakers } = testTakerRQ.useGetTestTakersOptions()

    // useEffect(() => { setPulldownDefaultValue() }, [testTakers, groups, grades, affiliations, groups])
    useEffect(() => { handleGroup(headerFilters) }, [headerFilters.group])
    useEffect(() => { refetchTestTakers() }, [headerFilters.group, headerFilters.affiliation, headerFilters.grade, headerFilters.t_class])
    useEffect(() => {
        if (headerFilters.affiliation && headerFilters.grade)
            handleGrade(headerFilters)
        else cleanTClassess()
    }, [headerFilters])

    return (
        <Can I={"read"} an={"header"}>
            <WrapHeader>
                <WrapContent>
                    {pulldownsData.map((pulldown, index) => {
                        const { name, hide, handleChange } = pulldown
                        const input = {
                            value: headerFilters?.[name],
                            onChange: (value) => handleChange(name, value)
                        }
                        const Component = fieldsTyps[pulldown.component]
                        return (
                            hide ||
                            <WrapPullDown key={index}>
                                <Component input={input} {...pulldown} />
                            </WrapPullDown>
                        )
                    }
                    )}
                </WrapContent>

            </WrapHeader>
        </Can>
    )
}

export default Header