import React from 'react'
import { SvgIcon } from '@mui/material'
import { CustomIconButton } from './Styled'
import { useTheme } from 'styles'
import IconButtonGroup from './IconButtonGroup'

const IconButtons = (props) => {
    const { selected = props.btns[0].key, setSelected, btns } = props

    const { Layout } = useTheme()

    return (
        <div style={{ ...Layout.flex }}>
            {btns.map((btn) =>
                <IconButtonGroup
                    {...btn}
                    action={(e) => {
                        setSelected(e, btn.key)
                        btn?.action(e, btn.key, props)
                    }}
                    key={btn.key}
                    selected={selected === btn.key}
                />
            )}
        </div>
    )
}

export default IconButtons