import { WordsCrud } from '.'
import { Close, PanToolOutlined, Remove, Replay } from '@mui/icons-material'
import { SelectTime, wordHooks } from 'components/Words'

const MarkWordsData = () => {
    const updateTimePoint = wordHooks.useTimePoint()
    const updateWordEnd = wordHooks.useEnd()
    const removeWord = wordHooks.useOmission()
    const selectWordError = wordHooks.useError()
    const setRepitition = wordHooks.useRepitition()

    const selectTime = {
        label: null,
        border: true,
        options: [
            { id: null, name: "" },
            { id: 30, name: "30" },
            { id: 45, name: "45" },
            { id: 60, name: "60" },
            { id: 90, name: "90" }
        ],
        width: '60px'
    }

    const UISettings = {
        $bg: true,
        width: 30,
        height: 30,
        $border: true,
        size: "small"
    }


    const colorSettings = {
        error: "red",
        customStatus: "yellow",
        timePoint: "primary",
        end: "rating1",
        minorError: "rating4",
        removed: "dark"
    }

    const getColor = (key, val) => {
        const basicColor = "gray"

        if (val)
            return colorSettings[key]
        return basicColor
    }

    const settingsItems = [
        {
            keys: ["error", "minorError"],
            button: {
                action: selectWordError,
                ...UISettings
            },
            icon: {
                name: Close,
                fontSize: "small",
            }
        },
        {
            keys: ["customStatus"],
            button: {
                action: setRepitition,
                ...UISettings
            },
            icon: {
                name: Replay,
                fontSize: "small",
            }
        },
        {
            keys: ["timePoint"],
            component: SelectTime,
            wrap: {
                $hideBg: true,
                $hideBorder: true
            },
            props: {
                action: updateTimePoint,
                ...selectTime
            }
        },
        {
            keys: ["removed"],
            button: {
                action: removeWord,
                ...UISettings
            },
            icon: {
                name: Remove,
                fontSize: "small",
            }
        },
        {
            keys: ["end"],
            button: {
                action: updateWordEnd,
                ...UISettings
            },
            icon: {
                name: PanToolOutlined,
                fontSize: "small",
            },
        }
    ]

    const viewResultsSettings = {
        error: {
            hide: true,
        },
        minorError: {
            hide: true,
        },
        customStatus: {
            hide: true
        },
        timePoint: {
            disabled: true,
            hideEmpty: true
        },
        end: {
            disabled: true,
            hideEmpty: true,
            hide: true,
        },
        removed: {
            hide: true,
        }
    }

    return {
        UISettings,
        selectTime,
        showPointTime: true,
        viewResultsSettings,
        settingsItems,
        getColor,
        colorSettings
    }
}

export default MarkWordsData