import React, { useContext, useEffect, useState } from 'react'
import { AssessmentFiltersHeader } from 'components/School/AssessmentsAndTestTakers'
import { WrapDataGrid } from 'components/DataGrid/Style'
import { useSelector } from 'react-redux'
import { useTheme } from 'styles'
import { labHooks, WordDefsWithFrequency, WordFrequency } from '.'
import { Levels } from 'components'
import { isEmpty } from 'lodash'
import { IconButton } from '@mui/material'
import { actions } from 'store'
import { useDispatch } from 'react-redux'
import { useQueryClient } from 'react-query'
import { queryClient as qc } from 'keys'
import { CustomSection, SpaceBetween, XSSpaceBetween } from 'styles/GlobalStyle'
import { LinguisticLabContext } from './LinguisticLabContext'
import { TabButton } from 'components/Form'
import { GraphicEq, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Graphs from './Graphs'
import { gray } from 'styles/theme'
import { light } from 'styles/theme'
import { thirdly2 } from 'styles/theme'
import { LevelDials } from 'components/Levels'

const LinguisticLab = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const [openGraphs, setOpenGraphs] = useState(false)

    const { assessmentFilters } = useSelector(state => state.assessment)
    const { selectedTab, setSelectedTab } = useContext(LinguisticLabContext)

    const { Layout } = useTheme()

    const { frequencyItems } = labHooks.constants
    const { data: frequency, refetch: refetchFrequency, isFetching: getFrequencyLoading } = labHooks.useFrequency()
    const headerItems = labHooks.useHeader()
    const tabs = labHooks.useTabs()


    const removeQueries = () => {
        queryClient.removeQueries({ queryKey: qc.assessmentFrequency });
    }

    const cleanData = () => {
        dispatch(actions.cleanAssessmentFilters({}))
        removeQueries()
    }

    const handleTab = (index) => {
        setSelectedTab(index)
    }

    useEffect(() => {
        return () => {
            cleanData()
        }
    }, [])

    useEffect(() => {
        if (frequency)
            refetchFrequency()
    }, [selectedTab])

    return (
        <WrapDataGrid>
            <AssessmentFiltersHeader
                loading={getFrequencyLoading}
                disabledSearch={!assessmentFilters.assessment_def}
                headerItems={headerItems}
                refetchData={refetchFrequency}
                showLoading={true}
            />

            {isEmpty(frequency) ||
                <div style={{ ...Layout.justifyContentBetween, ...Layout.rowHCenter, height: "50px" }}>
                    <div style={{ ...Layout.flex }}>
                        {tabs.map((tab, index) =>
                            <React.Fragment>
                                <TabButton
                                    key={index}
                                    loading={getFrequencyLoading}
                                    selected={index == selectedTab}
                                    handleTab={() => handleTab(index)}
                                    {...tab}
                                />
                                <XSSpaceBetween />
                            </React.Fragment>


                        )}
                    </div>

                    <div>
                        <IconButton onClick={() => setOpenGraphs(true)}>
                            <GraphicEq />
                        </IconButton>
                    </div>
                </div>}

            {isEmpty(frequency) ||
                <React.Fragment>
                    <LevelDials
                        buttonSize={{ width: 210 }}
                        levels={frequencyItems} label="דירוג קריאוּת (readability):" />


                    <div style={{ ...Layout.col, ...Layout.lrGap, ...Layout.grow, ...Layout.overflow }}>
                        <CustomSection $border={thirdly2} $shrink height={50} $flex align="center">
                            {`תוצאות מבוססות  על ${frequency?.assessment_count} מבדקים על פי הסינון`}
                        </CustomSection>

                        <div style={{ ...Layout.grow, ...Layout.flex, ...Layout.fullHeight, ...Layout.lcGap, ...Layout.overflow }}>

                            <WordDefsWithFrequency
                                {...frequency}
                            />

                            {/* <div style={{ width: "140px", flexShrink: 0, ...Layout.alignItemsEnd, ...Layout.flex }}>
                            <Levels levels={frequencyItems} title="אחוזי קריאות:" />
                        </div> */}
                        </div>
                    </div>
                </React.Fragment>
            }

            {/* Left Drawer Graph*/}
            <Graphs
                open={openGraphs}
                setOpen={setOpenGraphs}
                wordDefCount={frequency?.word_defs.length} />

            {/* Bottom Drawer Stats */}
            <WordFrequency />
        </WrapDataGrid>
    )
}

export default LinguisticLab
