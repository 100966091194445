
const useGradeOfAllocation = () => {
    const gradeOfAllocation = {
        field: 'reading_assessment_grade_of_allocation_name',
        headerName: "שכבת ביצוע",
        width: 150,
        headerAlign: 'center',
        align: 'center',
        feature: "grade-of-allocation",
        role: "read",
    }

    return gradeOfAllocation
}

export default useGradeOfAllocation