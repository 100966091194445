import { Replay } from "@mui/icons-material"
import { RecordCrud } from "components/Record/hooks"
import { useSelector } from "react-redux"

const useRateBtns = () => {
    const { wavesurfer } = useSelector(state => state.wavesurfer)

    const changeDuration = (duration) => {
        wavesurfer.setPlaybackRate(duration)
    }

    const handleSeeking = () => {
        wavesurfer.skip(-2)
    }

    const rateBtns = [
        {
            id: 5,
            label: "שתי שניות",
            icon: <Replay />,
            color: "rating1",
            rightIcon: true,
            action: () => handleSeeking()
        },
        {
            id: 0.5,
            label: "0.5x",
            color: "dark",
            action: () => changeDuration(0.5),
            markSelected: true
        },
        {
            id: 0.75,
            label: "0.75x",
            color: "dark",
            action: () => changeDuration(0.75),
            markSelected: true

        },
        {
            id: 1,
            label: "1.0x",
            color: "dark",
            action: () => changeDuration(1),
            markSelected: true

        },
        {
            id: 1.5,
            label: "1.5x",
            color: "dark",
            action: () => changeDuration(1.5),
            markSelected: true

        },

    ]

    return rateBtns
}

export default useRateBtns