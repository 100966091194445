import React, { useEffect, useRef, useState } from 'react'
import { ratingRQ } from 'store/hooks'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from 'react-query'
import { queryClient as qc } from 'keys'
import { getAccuracyStatsByAssessmentFetch, getFactoredAccuracyStatsByAssessmentFetch, getFluencyStatsByAssessmentFetch, getFluencyStatsFetch } from 'store/middlewares/rating.crud'
import { useParams } from 'react-router-dom'
import { ratingHooks } from '.'
import RatingPage from './RatingPage'

const WrapRatingContext = () => {
    const [currentSection, setCurrentSection] = useState()
    const currentSectionRef = useRef()

    const viewGraphBtns = ratingHooks.useViewGraphBtns()

    const [reportDisplay, setReportDisplay] = useState()
    const [viewGraph, setViewGraph] = useState({
        fluency: viewGraphBtns[0].key,
        accuracy: viewGraphBtns[0].key,
        factored_accuracy: viewGraphBtns[0].key
    })

    const params = useParams()
    const { assessment } = useSelector(state => state.assessment)
    const assessmentID = assessment.id || params?.assessmentID

    const { refetch: refetchScoreRating, data: scoreRating = {} } = ratingRQ.useGetScoreRatingMethod()

    const { data: accuracyStats, mutateAsync: refetchAccuracyStats } = useMutation({
        mutationKey: qc.accuracyStatsByAssessment,
        mutationFn: async (params) => {
            const res = await getAccuracyStatsByAssessmentFetch({ id: assessmentID, params })
            return res.data
        },
    })
    const { data: factoredAccuracyStats, mutateAsync: refetchFactoredAccuracyStats } = useMutation({
        mutationKey: qc.factoredAccuracyStats,
        mutationFn: async (params) => {
            const res = await getFactoredAccuracyStatsByAssessmentFetch({ id: assessmentID, params })
            return res.data
        },
    })
    const { data: fluencyStats, mutateAsync: refetchFluencyStats } = useMutation({
        mutationKey: qc.fluencyStats,
        mutationFn: async (params) => {
            const res = await getFluencyStatsByAssessmentFetch({ id: assessmentID, params })
            return res.data
        }
    })

    useEffect(() => {
        if (assessment?.id) {
            refetchAccuracyStats(viewGraphBtns[0].params)
            refetchFactoredAccuracyStats(viewGraphBtns[0].params)
            refetchFluencyStats(viewGraphBtns[0].params)
            refetchScoreRating()
        }
    }, [assessment?.id])

    const commonState = {
        scoreRating,
        factoredAccuracyStats,
        accuracyStats,
        fluencyStats,
        reportDisplay,
        currentSectionRef,
        currentSection, setCurrentSection,
        viewGraph, setViewGraph,
        setReportDisplay,
        refetchAccuracyStats,
        refetchFactoredAccuracyStats,
        refetchFluencyStats
    }

    return (
        <RatingContext.Provider value={commonState}>
            <RatingPage />
        </RatingContext.Provider>
    )
}

export const RatingContext = React.createContext()
export default WrapRatingContext