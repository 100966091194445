import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AllAssessmentsCrud } from './hooks';
import { Assessments } from 'components/School/AssessmentsAndTestTakers';
import { headercss } from './Style'
import { dataGridModel } from 'keys';
import { assessmentRQ } from 'store/hooks';
import { actions } from 'store';
import { assessmentUtils } from 'helpers';
import { useDispatch } from 'react-redux';
import { performAssessmentsHooks } from '.';

const PerformAssessments = () => {
    const dispatch = useDispatch()
    const [showAssessmentGroup, setShowAssessmentGroup] = useState(true)
    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters } = useSelector(state => state.assessment)
    const { testTaker } = useSelector(state => state.testTaker)

    const fields = performAssessmentsHooks.useFields()
    const headerItems = performAssessmentsHooks.useHeaderItems()

    const {
        cleanData,
    } = AllAssessmentsCrud()

    const { data: assessmentsData, refetch: refetchAssessments, remove: removeAssessments, isFetching: assessmentsLoading } = assessmentRQ.useGetAssessments()

    const refetchData = () => {
        removeAssessments()
        refetchAssessments()
    }

    useEffect(() => {
        refetchData()
    }, [
        headerFilters,
        testTaker,
        assessmentFilters?.year,//const value
        assessmentFilters?.semester//const value
    ])

    useEffect(() => {
        dispatch(actions.updateAssessmentFilters({
            semester: assessmentUtils.getCurrentSemester(),
            year: new Date().getFullYear()
        }))
        return (() => {
            cleanData()
        })
    }, [])

    //group assessments
    const firstAssessments = assessmentsData?.data && Object.values?.(
        assessmentsData?.data.reduce((acc, obj) => {
            if (!acc[obj.test_taker_id]) {
                acc[obj.test_taker_id] = { ...obj, assessment_count: 1, assessment_group: [obj] };
            } else {
                acc[obj.test_taker_id].assessment_count += 1;
                acc[obj.test_taker_id].assessment_group.push(obj)
            }
            return acc;
        }, {})
    );

    const allAssessments = assessmentsData?.data && Object.values?.(
        assessmentsData?.data.reduce((acc, obj) => {
            acc[obj.id] = { ...obj, assessment_count: 1, assessment_group: [obj] };
            return acc;
        }, {})
    );

    return (
        <Assessments
            visibilityModelKey={dataGridModel.all_assessments}
            fields={fields}
            headerItems={[...headerItems, {
                component: "button",
                action: () => setShowAssessmentGroup(!showAssessmentGroup),
                label: showAssessmentGroup ? "בטל קיבוץ מבדקים" : "קבץ מבדקים לפי תלמיד",
                height: "40px",
                color: "secondary",
                feature: "ungroup-assessment",
                permissionHOC: "hidden",
                role: "read"
            }]}
            refetchData={refetchData}
            loading={assessmentsLoading}
            data={showAssessmentGroup ? firstAssessments : allAssessments}
        />
    );
}

export default PerformAssessments
