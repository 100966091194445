import { useDispatch, useSelector, } from "react-redux"
import { actions } from 'store'
import { useRef, useCallback } from "react"

const useOnMount = () => {
    const { wavesurfer } = useSelector(state => state.wavesurfer)
    const { assessment } = useSelector(state => state.assessment)

    const wavesurferRef = useRef()
    const stopAudioAfterSecondsRef = useRef()

    const dispatch = useDispatch()

    const handleWSMount =
        useCallback(
            (waveSurfer, stop) => {
                wavesurferRef.current = waveSurfer;

                if (wavesurferRef.current) {
                    dispatch(actions.setWavesurfer(wavesurferRef.current))
                    dispatch(actions.setIsPlaying(false))

                    wavesurferRef.current.on("ready", () => {
                        stopAudioAfterSecondsRef.current = false
                    });

                    if (stop)
                        wavesurferRef.current.on('audioprocess', function () {

                            const currentTime = wavesurferRef.current.getCurrentTime()

                            // Check if playback time is greater than or equal to 50 seconds
                            if (Math.floor(currentTime) === stop) {
                                // Execute your logic when playback reaches 50 seconds
                                if (!stopAudioAfterSecondsRef.current) {

                                    wavesurferRef.current.pause()
                                    dispatch(actions.setIsPlaying(false))

                                    stopAudioAfterSecondsRef.current = true
                                }
                                // Add your custom actions here
                            }

                        }
                        )

                    if (window) {
                        window.surferidze = wavesurferRef.current;
                    }
                }
            }, [assessment, wavesurfer],
        );

    return handleWSMount
}

export default useOnMount