import { Add } from "@mui/icons-material"
import { useContext } from "react"
import { WordsContext } from "../Words"
import { statuses, types, updateType } from "keys"
import { useQueryClient } from "react-query"
import { queryClient as qc } from 'keys'
import { addItem, changeItem, removeItem } from "utils/array"
import { actions } from "store"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const useWordInsertion = () => {
    const dispatch = useDispatch()
    const { words } = useSelector(state => state.words)
    // const { words } = useContext(WordsContext)
    const queryClient = useQueryClient();

    const addWord = (word, index) => {
        const nextWord = words?.[index + 1]
        let nWords

        if (
            index + 1 == words.length ||
            nextWord.word_def_id
            // ||
            // words?.[index + 1].update_type == updateType.delete
        ) {
            const newWord = {
                transcription: "",
                status: types.incorrect,
                reading_assessment: word.reading_assessment,
                index: index + 1,
                update_type: updateType.update,
                errors: [
                    {
                        type: types.insertion,
                        status: types.insertion
                    }
                ]
            }
            nWords = addItem(words, index + 1, newWord)
        }

        else {
            let update_type = updateType.delete
            if (nextWord.update_type == updateType.delete)
                update_type = updateType.update

            nWords = changeItem(words, index + 1, { ...nextWord, update_type })
        }
        dispatch(actions.setWords(nWords))
    }

    const wordInsertion = {
        action: addWord,
        icon: <Add fontSize="small" />,
        size: "small",
        width: 30,
        height: 30
    }

    return wordInsertion
}

export default useWordInsertion