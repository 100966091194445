import React, { useContext } from 'react'
import { LinguisticLabContext } from './LinguisticLabContext'
import { Word } from 'components/Words'

const WordCell = (props) => {
    const { value } = props
    const { selectedWordDef } = useContext(LinguisticLabContext)

    const errorTypes = {
        error: {
            error: true
        },
        omission_and_error: {
            removed: true,
            error: true
        },
        self_correction_and_minor_error: {
            customStatus: true,
            error: true,
            minorError: true
        },
        omission_and_minor_error: {
            minorError: true,
            removed: true
        },
        minor_error: {
            minorError: true
        },
        self_correction: {
            customStatus: true,
            error: true
        },
        repetition: {
            customStatus: true,
        }
    }

    return (
        <Word
            tooltipPlacement={"right"}
            small
            {...errorTypes[value]}
            {...selectedWordDef}
        />
    )
}

export default WordCell