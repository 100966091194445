import { ReactComponent as ChildIcon } from 'assets/icons/Child.svg';
import { ReactComponent as ChildrenIcon } from 'assets/icons/Children.svg';
import { ReactComponent as GlobusIcon } from 'assets/icons/Globus.svg';
import { useSelector } from 'react-redux';


const useViewGraphBtns = () => {
    const { testTaker } = useSelector(state => state.testTaker)
    const { assessment } = useSelector(state => state.assessment)

    const viewGraphBtns = [

        {
            key: "single",
            icon: ChildIcon,
            tooltip: testTaker?.test_taker_name,
            placement: "top",
            action: () => { },
            params: {
                test_taker: assessment.test_taker_id
            },
            iconSize: {

            }
        },
        {
            key: "t_class",
            icon: ChildrenIcon,
            placement: "top",
            tooltip: "כל הכיתה",
            action: () => { },
            params: {
                t_class: testTaker.t_class
            }
        },
        {
            key: "grade",
            tooltip: "כל השכבה",
            placement: "top",
            icon: GlobusIcon,
            action: () => { },
            params: {
                grade_of_allocation: assessment.reading_assessment_grade_of_allocation
            }
        }
    ]

    return viewGraphBtns

}

export default useViewGraphBtns