import { lightDark, secondary } from "styles/theme"
import { MarkingWordsCrud } from "."
import { useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { markingWordsHooks } from ".."

const useActionBtns = () => {
    const {
        markingWordsSaveAndNext, markingWordsFinish, updateBrokenFileStatus,
        saveWordsLoading, markingWordsCompleteLoading, updateBrokenFileStatusLoading
    } = MarkingWordsCrud()

    const { assessment } = useSelector(state => state.assessment)

    const completeLoading = saveWordsLoading || markingWordsCompleteLoading
    const completeDisabled = !assessment.timePoint?.word_def_id || completeLoading

    const { saveDraft, loading: saveDraftLoading } = markingWordsHooks.useSaveDraft()

    const actionBtns = [
        {
            id: 0,
            label: saveDraftLoading || "הפסקה",
            action: saveDraft,
            color: "thirdly",
            loading: saveDraftLoading,
            width: 110,
            height: "35px",
            disabled: saveDraftLoading,
            disabledColor: "gray2"
        },
        {
            id: 2,
            label: completeLoading || "שמירה ומעבר למבדק הבא",
            action: markingWordsSaveAndNext,
            color: "secondary",
            loading: completeLoading,
            width: 200,
            disabled: completeDisabled,
            themeColor: secondary,
            disabledColor: "gray2",
            height: "35px"
        },
        {
            id: 3,
            label: completeLoading || "סיום",
            action: markingWordsFinish,
            color: "lightDark",
            loading: completeLoading,
            width: 80,
            disabled: completeDisabled,
            themeColor: lightDark,
            disabledColor: "gray2",
            height: "35px",
        }
    ]

    return actionBtns
}

export default useActionBtns
