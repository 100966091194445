import { types, updateType } from "keys"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"


const useError = () => {
    const dispatch = useDispatch()
    const { words } = useSelector(state => state.words)
    const { correct, incorrect } = types

    const selectWordError = (index) => {
        let nWord = { ...words[index] }
        //cancel removed 
        // nWord.removed = false
        // nWord.status = correct

        const { status = correct } = nWord

        //first click
        if (status === correct && !nWord.minorError)
            nWord.status = incorrect

        //third click
        if (nWord.minorError) {
            nWord.minorError = false
            nWord.status = correct
        }
        //second click
        else if (!nWord.minorError && status === incorrect) {
            nWord.minorError = true
        }

        nWord.update_type = updateType.update
        dispatch(actions.updateSingleWord({ index: index, word: nWord }))
    }
    return selectWordError
}

export default useError