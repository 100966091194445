import React, { useEffect, useState } from 'react';
import { Button, Tooltip, ClickAwayListener } from '@mui/material';
import { WrapComment, CommentTitle } from './Styled'
import { Close } from '@mui/icons-material';
import { useTheme } from 'styles';
import { CacheProvider } from '@emotion/react';
import { cacheLtr } from 'styles/theme';

const Comment = (props) => {
    const { children, title, open, right, bottom, placement = "left" } = props
    const [openVal, setOpenVal] = useState(true)

    const { Layout, Fonts } = useTheme()

    // useEffect(() => {
    //     setOpenVal(open)
    // }, [open])

    const handleTooltipClose = () => {
        setOpenVal(false);
    };

    const handleTooltipOpen = () => {
        setOpenVal(true);
    };

    return (
        title ?
            <ClickAwayListener onClickAway={() => { }}>
                <div>
                    <CacheProvider value={cacheLtr}>
                        <WrapComment
                            bottom={bottom}
                            right={right}
                            arrow
                            PopperProps={{
                                disablePortal: true,
                            }}
                            TransitionProps={{ timeout: 1500 }}
                            // onClose={handleTooltipClose}
                            open={open && openVal}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement={placement}
                            title={
                                <CommentTitle>
                                    <div className='comment-icon'>
                                        <Close fontSize='small' color="thirdly2" onClick={e => handleTooltipClose(e)}></Close>
                                    </div>
                                    <div className='comment-title' style={{ ...Layout.grow, height: 70, ...Fonts.textDark }}>
                                        {title}
                                    </div>
                                </CommentTitle>
                            }
                        >
                            {children}
                        </WrapComment>
                    </CacheProvider>
                </div>
            </ClickAwayListener>
            :
            children
    );
}

export default Comment
