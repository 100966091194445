import { types, updateType } from "keys"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"

const useRepitition = () => {
    const dispatch = useDispatch()
    const { words } = useSelector(state => state.words)
    const { incorrect } = types

    const setRepitition = (index) => {
        let nWord = { ...words[index] }
        nWord.customStatus = !nWord.customStatus
        nWord.update_type = updateType.update
        nWord.removed = false
        // nWord.status = nWord.status === incorrect || undefined
        dispatch(actions.updateSingleWord({ index: index, word: nWord }))
    }

    return setRepitition
}

export default useRepitition