import { useDispatch } from "react-redux"
import { actions } from "store"


const useTimePoint = () => {
    const dispatch = useDispatch()
    
    const updateDynamicPointTime = (value, word_def_id) => {
        if (value)
            if (Number(value) <= 60) {
                const timePoint = {
                    word_def_id: word_def_id,
                    value: value
                }
                dispatch(actions.updateAssessment({ timePoint: timePoint }))
            }
            else {
                alert("הכנס מספר עד 60")
            }
        else
            dispatch(actions.updateAssessment({ timePoint: {} }))
    }

    return updateDynamicPointTime
}

export default useTimePoint