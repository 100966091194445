import React, { createContext, useEffect, useRef, useState } from 'react'
import { WrapAssessmentRecord } from './Style'
import { Mic, Progress } from '.'
import { AssessmentDef } from 'components'
import { InstructionsButton } from '../MakingRecordAssessments'

const AssessmentRecord = (props) => {
    const { customActions } = props

    const [isRecording, setIsRecording] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [blobURL, setBlobURL] = useState('')
    const [audioURL, setAudioURL] = useState('')
    const [createAssessmentIsRun, setCreateAssessmentIsRun] = useState('')
    const [isBlocked, setIsBlocked] = useState(false)
    const [showBackdrop, setShowBackdrop] = useState(false)
    const [closeBackdrop, setCloseBackdrop] = useState(false)
    // const [, setShowBackdrop] = useState(false)
    const [audioChunks, setAudioChunks] = useState([])
    const [openMicPopver, setOpenMicPopver] = useState(false)
    const [openUploadPopver, setOpenUploadPopver] = useState(false)

    const audioRef = useRef()
    const isRecordingRef = useRef(false)

    const commonValue = {
        isRecording, setIsRecording,
        blobURL, setBlobURL,
        isBlocked, setIsBlocked,
        isPlaying, setIsPlaying,
        createAssessmentIsRun, setCreateAssessmentIsRun,
        showBackdrop, setShowBackdrop,
        audioChunks, setAudioChunks,
        openMicPopver, setOpenMicPopver,
        openUploadPopver, setOpenUploadPopver,
        audioURL, setAudioURL,
        audioRef,
        isRecordingRef,
        closeBackdrop
    }

    useEffect(() => {
        isRecordingRef.current = isRecording
    }, [isRecording])

    return (
        <MicContext.Provider value={commonValue}>
            <AssessmentDef
                customHeader={{
                    right: [
                        { component: "custom", customComponent: InstructionsButton }
                    ]
                }}
                student={true}
                comment={closeBackdrop && !showBackdrop}
                hidePoints
                hideBackBtn />
            <WrapAssessmentRecord>
                {showBackdrop && <Progress setClose={setCloseBackdrop} />}
                <Mic customActions={customActions} />
            </WrapAssessmentRecord>
        </MicContext.Provider>
    )

}
export const MicContext = createContext()
export default AssessmentRecord