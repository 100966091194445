import React from 'react'
import { Label, Mandatory, WrapLabel } from './Styled'
import PermissionHOC from 'components/Permissions/PermissionHOC'

const CustomLabel = (props) => {
    const { label, bold, fontSize, mandatory, className } = props

    return (
        <WrapLabel bold={bold} fontSize={fontSize} className={className}>
            <Label>{label}
                {mandatory && <Mandatory> *</Mandatory>}
            </Label>
        </WrapLabel>
    )
}

export default PermissionHOC(CustomLabel)