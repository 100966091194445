import React, { useContext } from 'react';
import { Tooltip } from '@mui/material';
import { WrapRecordButton } from './Style'
import { Comment } from 'components/Form'
import { MicContext } from './AssessmentRecord';

const RecordButton = (props) => {
    const { onClick, title, isRecording, color, children } = props

    const { showBackdrop, blobURL, openUploadPopver, setOpenUploadPopver } = useContext(MicContext)

    const comment = {
        title: !showBackdrop && "לחצו על הכפתור הכתום להתחלת הקלטה",
        open: (showBackdrop || !isRecording) && !blobURL,
        bottom: 22
    }

    return (
        <Comment {...comment}>
            <WrapRecordButton
                onClick={onClick}
                aria-label='record'
                recording={"true"}
                color='secondary'
            >
                {isRecording && <div className='pulse-bg' />}
                {children}
                {isRecording ? "סִיַּמְתִּי" : <props.icon fontSize="large" />}
            </WrapRecordButton>
        </Comment>
    );
};
export default RecordButton;