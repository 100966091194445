
const qc = {
    adminAssessments: "adminAssessments",
    words: "words",
    users: "users",
    fluencyStats: "fluencyStats",
    scoreRatingMethod: "scoreRatingMethod",
    assessment: "assessment",
    word: "word",
    wordDefs: "wordDefs",
    matchingLearning: "matchingLearning",
    accuracyStats: "accuracyStats",
    factoredAccuracyStats: "factoredAccuracyStats",
    factoredAccuracyStats: "factoredAccuracyStats",
    testTaker: "testTaker",
    allGrades: "allGrades",
    categories: "categories",
    rating: "rating",
    getAudioFile: "getAudioFile",
    initAIServer: "initAIServer",
    whisper: "whisper",
    assessmentDef: "assessmentDef",
    assessmentDefs: "assessmentDefs",
    prices: "prices",
    allAssessmentDefs: "allAssessmentDefs",
    fluencyStats: "fluencyStats",
    hesitationsStats: "hesitationsStats",
    factoredAccuracyStats: "factoredAccuracyStats",
    accuracyStatsByAssessment: "accuracyStatsByAssessment",
    tClassess: "tClassess",
    nestedWords: "nestedWords",
    vowelCodes: "vowelCodes",
    letterAddOns: "letterAddOns",
    letterDescriptions: "letterDescriptions",
    assessments: "assessments",
    getWithLastAssessments: "getWithLastAssessments",
    report: "report",
    testTakers: "testTakers",
    grades: "grades",
    dashboard: "dashboard",
    assessmentFrequency: "assessmentFrequency",
    assessmentFrequencyFactored: "assessmentFrequencyFactored",
    wordFrequency: "wordFrequency",
    assessmentAndWords: "assessmentAndWords"
}

export default qc