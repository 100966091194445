import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"


const useEnd = () => {
    const dispatch = useDispatch()
    const { assessment } = useSelector(state => state.assessment)

    const updateWordEnd = (index, wordId) => {
        if (assessment.end === wordId)
            dispatch(actions.updateAssessment({ end: null }))
        else
            dispatch(actions.updateAssessment({ end: wordId }))
    }

    return updateWordEnd
}

export default useEnd