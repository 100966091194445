import React from 'react';
import { Header, WrapFiltersHeader } from './Style';
import { Button, fieldsTyps } from 'components/Form';
import { assessmentsHooks } from 'components/School/Assessments';
import { useSelector } from 'react-redux';
import { queryClient as qc } from 'keys';
import { getAssessmentDefsFetch } from 'store/middlewares/assessment.crud';
import { useQuery } from 'react-query';

const Assessments = (props) => {
    const headerItemsP = assessmentsHooks.useHeaderItems()
    const { showLoading, headerItems = headerItemsP, disabledSearch, headercss, refetchData, loading } = props

    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters } = useSelector(state => state.assessment)

    const { data } = useQuery({
        queryKey: [qc.assessmentDefs, assessmentFilters?.category, headerFilters],
        refetchOnWindowFocus: false, refetchOnMount: true, retry: 0,
        queryFn: ({ signal }) => getAssessmentDefsFetch({ headerFilters, assessmentFilters, signal, })
    })

    return (
        <WrapFiltersHeader>
            <Header headercss={headercss}>
                {headerItems.map((headerBtn, index) => {
                    let Component = fieldsTyps[headerBtn.component]

                    return (
                        <Component
                            {...headerBtn}
                            key={index}
                            fullwidth
                            options={headerBtn.name === 'assessment_def' ? data?.data : headerBtn.options}
                            input={{
                                ...headerBtn?.input,
                                value: headerBtn?.input?.value
                            }}
                        />
                    )
                })}
                <Button
                    disabled={disabledSearch}
                    label="חיפוש"
                    action={refetchData}
                    height={'40px'}
                    width={'80px'}
                    color={"thirdly"}
                    loading={showLoading && loading}
                />
            </Header>
        </WrapFiltersHeader>
    );
}

const isRender = (prev, next) => {
    return false
}

export default React.memo(Assessments, isRender)
