import { Transcription, AudioTranscription, CreateAssessments, MakingRecordAssessments, LearningResources, CreateAssessmentRecord, AssessmentDef, UploadAudioFile, CreateAssessment, Rating, Assessment, MatchingLearning, TestTakers, PerformAssessments, AssessmentDefs, Assessments, AdminAssessments, MarkingWords, UploadAudio, Login, LinguisticLab, MarkingWordsAssessments, AudioCuttingAssessments, AudioCutting, TranscriptionAssessments, Register, Dashboard, Payments, ContactUs, Users } from 'components'

import { transcription, createAssessment, uploadAudioFile, rating, assessment, matchingLearning, createAssessmentRecord, assessmentDefs, testTakers, assessments, allAssessments, createAssessments, assessmentDef, adminAssessments, markingWords, cutAudio, login, assessmentView, makingRecordAssessments, learningResources, audioTranscription, linguisticLab, audioCutting, dashboard, app, payments, contactUs, users } from "components/Router/routes";
import { Navigate } from 'react-router-dom';

const useAppRoutes = () => {
    const permissionParams = {
        permissionHOC: "hidden",
        role: "read"
    }

    const appRoutes = [
        {
            id: 123,
            path: dashboard,
            element: Dashboard,
            feature: "dashboard",
            isGeneralPage: true,
            ...permissionParams
        },
        {
            id: 0,
            path: createAssessment,
            element: CreateAssessment,
            feature: "create-assessment",
            ...permissionParams
        },
        {
            id: 10,
            path: testTakers,
            element: TestTakers,
            isGeneralPage: true,
            feature: "test-takers",
            ...permissionParams
        },
        {
            id: 1,
            path: rating,
            element: Rating,
            feature: "rating",
            ...permissionParams
        },
        {
            id: 22,
            path: `${assessmentView}/:assessmentID`,
            element: Assessment,
            feature: "assessment",
            ...permissionParams
        },
        {
            id: 22,
            path: `${assessmentView}`,
            element: Assessment,
            feature: "assessment",
            ...permissionParams
        },
        {
            id: 3,
            path: `${matchingLearning}/:assessmentID`,
            element: MatchingLearning,
            feature: "matching-learning",
            ...permissionParams
        },
        {
            id: 4,
            path: assessmentDef,
            element: AssessmentDef,
            feature: "assessment-def",
            ...permissionParams
        },
        {
            id: 6,
            path: `${createAssessmentRecord}/:testTakerID`,
            element: CreateAssessmentRecord,
            feature: "create-record-assessment",
            ...permissionParams
        },
        {
            id: 666,
            path: makingRecordAssessments,
            element: MakingRecordAssessments,
            feature: "create-record-assessment",
            ...permissionParams
        },
        {
            id: 7,
            path: assessments,
            element: Assessments,
            isGeneralPage: true,
            feature: "assessments",
            ...permissionParams
        },
        {
            id: 77,
            path: `${assessments}/:assessmentID`,
            element: Rating,
            feature: "assessment",
            ...permissionParams
        },
        {
            id: 8,
            path: assessmentDefs,
            element: AssessmentDefs,
            feature: "assessment-defs",
            ...permissionParams
        },
        {
            id: 88,
            path: `${assessmentDefs}/:assessmentDefID`,
            element: AssessmentDef,
            feature: "assessment-defs",
            ...permissionParams
        },
        {
            id: 9,
            path: allAssessments,
            element: PerformAssessments,
            isGeneralPage: true,
            feature: "all-assessments",
            ...permissionParams
        },
        {
            id: 10,
            path: createAssessments,
            element: CreateAssessments,
            feature: "create-assessments",
            isGeneralPage: true,
            ...permissionParams
        },
        {
            id: 11,
            path: `${uploadAudioFile}/:testTakerID`,
            element: UploadAudio,
            feature: "create-upload-assessment",
            ...permissionParams
        },
        {
            id: 6,
            path: adminAssessments,
            element: AdminAssessments,
            isGeneralPage: true,
            feature: "admin-assessments",
            ...permissionParams
        },
        {
            id: 666,
            path: `${app.markingWordsAssessments}`,
            element: MarkingWordsAssessments,
            feature: "admin-assessments",
            isGeneralPage: true,
            ...permissionParams
        },
        {
            id: 667,
            path: `${app.markingWordsById}`,
            element: MarkingWords,
            feature: "admin-assessments",
            ...permissionParams
        },
        {
            id: 555,
            path: `${app.audioCuttingAssessments}`,
            element: AudioCuttingAssessments,
            feature: "admin-assessments",
            isGeneralPage: true,
            ...permissionParams,
        },
        {
            id: 667,
            path: `${app.audioCuttingById}`,
            element: AudioCutting,
            feature: "admin-assessments",
            ...permissionParams
        },
        {
            id: 777,
            path: `${app.transcriptionAssessments}`,
            element: TranscriptionAssessments,
            feature: "admin-assessments",
            isGeneralPage: true,
            ...permissionParams,
        },
        {
            id: 778,
            path: `${app.transcriptionById}`,
            element: Transcription,
            feature: "admin-assessments",
            ...permissionParams
        },
        {
            id: 7,
            path: learningResources,
            element: LearningResources,
            isGeneralPage: true,
            feature: "voice-lab",
            ...permissionParams
        },
        {
            id: 77,
            path: audioTranscription,
            element: AudioTranscription,
            isGeneralPage: true,
            feature: "marking-words",
            ...permissionParams
        },
        {
            id: 9,
            path: `${transcription}/:assessmentID`,
            element: Transcription,
        },

        {
            id: 13,
            path: linguisticLab,
            element: LinguisticLab,
            isGeneralPage: true,
            feature: "linguistic-lab",
            ...permissionParams
        },
        {
            id: 133,
            path: users,
            element: Users,
            isGeneralPage: true,
            feature: "users",
            ...permissionParams
        },
        {
            id: 5,
            path: "/",
            navigate: app.dashboard,
            isGeneralPage: true,
            feature: "test-takers",
            ...permissionParams
        },
        {
            id: 55,
            path: `/`,
            navigate: app.markingWordsAssessments,
            isGeneralPage: true,
            feature: "admin-assessments",
            ...permissionParams
        }
    ]

    return appRoutes
}

export default useAppRoutes