import React, { useState } from 'react'
import { WrapIDS } from './Style'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'


const IDSCell = (props) => {
    const { row } = props
    const { count, type } = row
    const [index, setIndex] = useState(0)

    const errorTypes = {
        error: [683, 636, 681, 676, 679],
        omission_and_error: [672, 680, 663, 654, 635],
        self_correction_and_minor_error: [666, 668, 639, 637, 684],
        omission_and_minor_error: [681, 638, 640, 639, 647],
        minor_error: [646, 655, 641, 643, 657],
        self_correction: [649, 642, 648, 644, 679],
        repetition: [675, 671, 649, 641, 639],
        correct: [639, 643, 653, 659, 670]
    }

    const goNext = () => {
        setIndex(index + 1)
    }

    const goPrev = () => {
        setIndex(index - 1)
    }

    return (
        !!count &&
        <WrapIDS index={index} len={5}>
            <ChevronRight className='next-icon' onClick={goPrev} />
            {errorTypes[type][index]}
            <ChevronLeft className='prev-icon' onClick={goNext} />
        </WrapIDS>
    )
}

export default IDSCell