import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { markingWordsHooks } from '.'
import { Backdrop } from '@mui/material'
import { useSelector } from 'react-redux'

const StopTimmer = () => {
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const { isPlaying } = useSelector(state => state.wavesurfer)
    const { isCloseSttoper } = useSelector(state => state.markingWords)

    const { saveDraft } = markingWordsHooks.useSaveDraft()

    const onIdle = () => {
        if (!isCloseSttoper && !isPlaying) {
            saveDraft()
            setOpenBackdrop(true)
        }
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        timeout: 10_000,
        throttle: 500
    })

    return (
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={openBackdrop}
            onClick={() => setOpenBackdrop(false)}
        >
            <div>
                <h3>
                    {" לא בוצעה פעולה בשניות האחרונות, הועברת למצב הפסקה"}
                </h3>
            </div>
        </Backdrop>
    )
}

export default StopTimmer
