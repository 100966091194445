import React from "react";
import { WrapWordP, WordP, PointTime } from './Style'
import { Tooltip } from "@mui/material";
import { errorHooks } from "./ErrorTypes";

const Word = (props) => {
    const { tooltipPlacement, small, error, removed, minorError, customStatus, viewResults, is_time_point_word, time_point, transcription, word_def_text } = props

    const getTooltipTitle = errorHooks.useGetTooltipTitle()

    let tooltipContent = getTooltipTitle(error, minorError, removed, customStatus)

    return (
        <WrapWordP $removed={removed} $minorError={minorError} $error={error} $customStatus={customStatus}>
            {viewResults && is_time_point_word &&
                <PointTime>
                    {time_point}
                </PointTime>}
            <Tooltip title={tooltipContent} placement={tooltipPlacement || "top"}>
                <WordP small={small} $error={error}>
                    {transcription || word_def_text}
                </WordP>
            </Tooltip>
        </WrapWordP>
    )
}

export default Word