import { SpeedDialAction } from "@mui/material";
import { styled as muiStyled } from '@mui/material/styles';
import styled from "styled-components";
import { black } from "styles/theme";


const WrapLevels = styled.div`
display: flex;
row-gap: 8px;
flex-direction: column;
color: white;
width:100%
`

const Title = styled.div`
color:black;
font-size:18px;
`

const Level = styled.div`
width:100%;
box-sizing:border-box;
background:${props => props.color};
border-radius: 2px;
padding:5px;
font-weight:400;
font-size:15px;
`

const DialAction = muiStyled(SpeedDialAction)(({ theme, color, borderStyle = "solid", borderColor }) => ({
    width: '170px',
    height: '30px',
    borderRadius: 8,
    color: black,
    boxShadow: "none",
    borderWidth: 2,
    borderColor: color,
    borderStyle: "solid",
    fontWeight: 400,
    background: "transparent"


    // width:100%;
    // // box-sizing:border-box;
    // background:${props => props.color};
    // border-radius: 2px;
    // padding:5px;
    // font-weight:400;
    // font-size:15px;
}));


export { DialAction, Title, WrapLevels, Level }