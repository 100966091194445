import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline";

const usePlugins = () => {
    const plugins = [
        {
            key: "top-timeline",
            plugin: TimelinePlugin,
            options: {
                height: 20,
                insertPosition: "beforebegin",
                style: {
                    color: "#2D5B88",
                },
                timeInterval: 0.1,
                primaryLabelInterval: 1,
            },

        },
        {
            key: "bottom-timeline",
            plugin: TimelinePlugin,
            options: {
                height: 10,
                style: {
                    color: "#6A3274",
                },
                primaryLabelInterval: 1,
                timeInterval: 0.1,
            },

        },
    ]

    return plugins
}

export default usePlugins