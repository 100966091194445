import MicRecorder from 'mic-recorder-to-mp3';
import { CreateAssessmentFormCrud } from 'components/School/CreateAssessment/CreateAssessmentForm/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { useContext } from 'react';
import { MicContext } from '../AssessmentRecord';
import { isEmpty } from 'lodash';
import { app } from 'components/Router/routes';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import { statuses } from 'keys';
import { StoreCrud } from 'store/hooks';
import { toast } from 'react-toastify';
import { SuccessToast } from 'components/School/MakingRecordAssessments';
import { toastConfig } from 'config';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const CreateAssessmentRecord = () => {
    const dispatch = useDispatch()
    const { createAssessment } = CreateAssessmentFormCrud()
    const { updateAssessmentStatus } = StoreCrud()
    const { state } = useLocation()
    const { assessment } = useSelector(state => state.assessment)

    const { isRecordingRef, isRecording, setAudioURL, audioRef, isPlaying, audioChunks, setAudioChunks, setShowBackdrop, setCreateAssessmentIsRun, setIsPlaying, blobURL, setBlobURL, setIsBlocked, setIsRecording, isBlocked } = useContext(MicContext)

    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isPaused,
        recordingTime,
        mediaRecorder
    } = useAudioRecorder();

    const navigate = useNavigate()

    const cleanRecord = () => {
        setAudioChunks([])
        setBlobURL('')
        audioRef.current = { ...audioRef.current, src: '' }
        setIsPlaying(false);
    }

    const completeAllBattery = () => {
        navigate(app.allAssessments)
        toast(<SuccessToast />, {
            ...toastConfig, style: {
                ...toastConfig.style,
                width: "550px",
                height: '85px'
            }
        })
    }

    const uploadAudioRecord = async () => {
        const { assessmentsGroup } = state

        setCreateAssessmentIsRun(true)
        const values = {
            assesment_def: assessment.reading_assessment_def_id,
            file: blobURL
        }

        if (assessment.id) {
            await updateAssessmentStatus(statuses.uploading)


            //go to next assessment if the assessment doesnt have a record
            if (assessmentsGroup.length) {

                const newAssessmentsGroup = [...assessmentsGroup];
                let assessmentIndex = assessmentsGroup.findIndex((nAssessment) => nAssessment.id === assessment.id)
                newAssessmentsGroup[assessmentIndex] = { ...newAssessmentsGroup[assessmentIndex], complete: true };

                //last step
                if (assessmentIndex === assessmentsGroup.length - 1)
                    completeAllBattery()

                else {
                    let nextAssessmentIndex = assessmentIndex + 1
                    //Go to the next incomplete assessment
                    while (assessmentsGroup[nextAssessmentIndex]?.complete)
                        nextAssessmentIndex++

                    if (assessmentsGroup[nextAssessmentIndex]) {
                        const currentAssessment = assessmentsGroup[nextAssessmentIndex]
                        navigate(`${app.makingRecordAssessments}?assessment=${currentAssessment.id}&assessmentDef=${currentAssessment.reading_assessment_def_id}`, {
                            state: {
                                assessmentsGroup: newAssessmentsGroup
                            }
                        });
                        dispatch(actions.setAssessment(currentAssessment))
                    }
                    //all the steps are complete
                    else completeAllBattery()
                }
            }
        }

        else navigate(app.allAssessments)

        setCreateAssessmentIsRun(false)
        cleanRecord()
        createAssessment(values)
    }

    const playPause = () => {
        setIsPlaying(!isPlaying)
    }

    const startRecord = () => {
        Mp3Recorder
            .start()
            .then(() => {
                setIsRecording(true)
            }).catch((e) => console.error(e));
    }

    const start = () => {
        if (isBlocked) {
            toast('"נראה שהרמקול שלכם לא עובד. הפעילו אותו כדי שנוכל להתחיל באבחון', toastConfig)
            console.log('Permission Denied');
        }
        else {
            if (isEmpty(audioChunks)) {
                setShowBackdrop(true)
                setTimeout(() => {
                    setShowBackdrop(false)
                    startRecord()
                }, 3000);
            }
            else startRecord()
        }
    };

    const downloadRecording = () => {
        console.log("assessment", assessment)
        if (blobURL) {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blobURL);
            downloadLink.download = 'recorded_audio.wav';
            downloadLink.click();
        }
    };

    const stop = () => {
        Mp3Recorder
            .stop()
            ?.getMp3()
            ?.then(([buffer, blob]) => {
                let nAudioChunks = [...audioChunks, blob]
                setAudioChunks(nAudioChunks);
                setIsRecording(false)
                const audioBlob = new Blob(nAudioChunks, { type: 'audio/mp3' });
                setBlobURL(audioBlob)
                setAudioURL(URL.createObjectURL(audioBlob))
            }).catch((e) => {
                console.log(e)
            });
    };

    const getPermission = () => {
        navigator.getUserMedia = (navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);
        navigator.getUserMedia({ audio: true },
            () => {
                console.log('Permission Granted');
                setIsBlocked(false)
            },
            () => {
                console.log('Permission Denied');
                setIsBlocked(true)

            },
        );
    }

    const initMic = () => {
        getPermission()
    }

    return {
        downloadRecording,
        initMic,
        start,
        stop,
        uploadAudioRecord,
        startRecording,
        stopRecording,
        togglePauseResume,
        playPause,
        cleanRecord,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
        mediaRecorder,
    }
}
export default CreateAssessmentRecord