import { useSelector } from 'react-redux';
import { semesters } from 'keys';
import { actions } from 'store';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { assessmentDefRQ } from 'store/hooks';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { queryClient as qc } from 'keys'

const useHeaderItems = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const { grades } = useSelector(state => state.testTaker)
    const { assessmentFilters, categories } = useSelector(state => state.assessment)

    // const { data: assessmentDefsData } = assessmentDefRQ.useGetAssessmentDefs()

    const handleChangeAssessmentsFilters = (key, value) => {
        const nAssessmentFilters = { ...assessmentFilters, [key]: value }
        dispatch(actions.setAssessmentFilters(nAssessmentFilters))
    }

    const assessmentDefsData = queryClient.getQueryData(qc.assessmentDefs)

    const headerItems = {
        year: {
            id: 1,
            placeholder: "שנה",
            options: [{ id: 2024, name: "2024" }],
            name: "year",
            input: {
                value: assessmentFilters?.year,
                onChange: (value) => handleChangeAssessmentsFilters('year', value)
            },
            component: "autoComplete",
            width: 100
        },
        semester: {
            id: 2,
            placeholder: "סמסטר",
            options: semesters,
            name: "semester",
            input: {
                value: assessmentFilters?.semester,
                onChange: (value) => handleChangeAssessmentsFilters('semester', value)
            },
            component: "autoComplete",
            width: 180,
            disabled: isEmpty(assessmentFilters?.year) && isEmpty(assessmentFilters?.grade_of_allocation)
        },
        grade_of_allocation: {
            id: 1,
            placeholder: "שכבה בעת ביצוע",
            options: grades,
            name: "grade_of_allocation",
            input: {
                value: assessmentFilters?.grade_of_allocation,
                onChange: (value) => handleChangeAssessmentsFilters('grade_of_allocation', value)
            },
            component: "autoComplete",
            width: 100
        },
        category: {
            id: 0,
            placeholder: "סוג מבדק",
            options: categories,
            name: "category",
            input: {
                value: assessmentFilters?.category,
                onChange: (value) => handleChangeAssessmentsFilters("category", value)
            },
            component: "autoComplete",
            labelKey: "label",
            multiple: true,
        },
        assessment_def: {
            id: 3,
            placeholder: "מבדק",
            // options: queryClient.getQueryData(qc.assessmentDefs)?.data,
            name: "assessment_def",
            input: {
                value: assessmentFilters?.assessment_def,
                onChange: (value) => {
                    handleChangeAssessmentsFilters('assessment_def', value || [])
                }
            },
            component: "autoComplete",
            multiple: true,
            width: 250
        }
    }

    return headerItems
}

export default useHeaderItems