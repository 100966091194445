import React, { useEffect, useRef } from 'react';
import { AssessmentsCrud } from './hooks';
import { useSelector } from 'react-redux';
import { Assessments } from 'components/School/AssessmentsAndTestTakers';
import { dataGridModel } from 'keys';
import { assessmentRQ } from 'store/hooks';
import { assessmentsHooks } from '.';
import { map } from 'lodash';

const AssessmentsC = () => {
    const { cleanData, setRowSelectionModel } = AssessmentsCrud()
    const { headerFilters } = useSelector(state => state.header)
    const { testTaker } = useSelector(state => state.testTaker)

    const fields = assessmentsHooks.useFields()
    const headerBtns = assessmentsHooks.useHeaderBtns()
    const headerItems = assessmentsHooks.useHeaderItems()
    const { refetch: refetchGetAssessmentReport, isFetching: assessmentReportLoading, remove: removeGetAsessmentReport, data } = assessmentRQ.useGetAssessmentReport()

    const refetchData = () => {
        removeGetAsessmentReport()
        refetchGetAssessmentReport()
    }

    useEffect(() => {
        removeGetAsessmentReport()
        refetchGetAssessmentReport()
    }, [
        //onChange header filters
        headerFilters,
        //for all assessment for specific testTaker
        testTaker
    ])

    useEffect(() => {
        return (() => {
            cleanData()
        })
    }, [])

    return (
        <Assessments
            data={data?.data}
            fields={fields}
            refetchData={refetchData}
            loading={assessmentReportLoading}
            headerItems={[...map(headerItems), ...headerBtns]}
            checkboxSelection
            onRowSelectionModelChange={setRowSelectionModel}
            visibilityModelKey={dataGridModel.assessments}
        />
    );
}

export default AssessmentsC
