import { useQuery } from "react-query"
import { queryClient as qc } from "keys"
import { getUsersFetch } from "store/middlewares/user.crud"

const useGetUsers = () => {
    return useQuery({
        queryKey: qc.users,
        refetchIntervalInBackground: false,
        queryFn: async () => {
            const { data } = await getUsersFetch()
            return data
        }
    })
}

export default { useGetUsers }