import './App.css';

import ThemeProviderMUI from '@mui/material/styles/ThemeProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import theme, { cacheRtl } from 'styles/theme'
import Router from 'components/Router';
import { actions } from 'store';

import { QueryClient, QueryClientProvider } from "react-query";
import { AbilityContext } from 'components/Permissions/Can';
import ability from 'config/ability'
import { history } from 'components/Router/navigationService';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CacheProvider } from '@emotion/react';

import Helmet from './Helmet'

const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(actions.initialFirebase())
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <AbilityContext.Provider value={ability(token)}>
        <CacheProvider value={cacheRtl}>
          <ThemeProviderMUI theme={theme}>
            <div className="App">
              <Helmet />
              <HistoryRouter history={history}>
                <Router />
              </HistoryRouter>
            </div>
          </ThemeProviderMUI>
        </CacheProvider>
        <ToastContainer />
      </AbilityContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
