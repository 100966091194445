import React from 'react';
import { Helmet } from 'react-helmet';

const App = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Go Read</title>
        <meta name="description" content="פלטפורמת בינה מלאכותית לאבחון קריאה מדוייק והתאמה אישית של למידה" />
        <meta name="keywords" content="בינה מלאכותית, אבחון קריאה ממוחשב, רכישת קריאה, אוריינות, כלי אבחון מבוסס AI, למידה מותאמת אישית" />
      </Helmet>
      {/* Your website content goes here */}
    </div>
  );
};

export default App;
