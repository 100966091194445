import { Drawer, drawerClasses } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { bg } from 'styles/theme';

const DrawerGraphStyles = muiStyled(Drawer)(({ theme, $systemUI }) => ({
    [`& .${drawerClasses.paper}, & .${drawerClasses.root}`]: {
        background: bg,
    },
    '.wrap-graph-content': {
        height: "300px",
        display: "flex",
        flexDirection: 'column',
        width: "100%",
        padding: "15px",
        rowGap: 15,
        boxSizing: "border-box"
    },
    '.icon-buttons': {
        height: "100px",
        flexShrink: 0,
        display: "flex",
        columnGap: '5px',
        flexDirection: "column",
        justifyContent: 'start',
        rowGap: 10
    },
    '.assessment-count': {
        display: "flex",
        columnGap: 3,
        fontWeight: "bold"
    }
}));

export { DrawerGraphStyles }