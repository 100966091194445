import ratingColors, { frequencyRagtingWithLabels } from "keys/rating"


const constants = {
    frequencyItems: [
        {
            label: frequencyRagtingWithLabels[0],
            color: ratingColors[0]
        },
        {
            label: frequencyRagtingWithLabels[1],
            color: ratingColors[1]
        },
        {
            label: frequencyRagtingWithLabels[2],
            color: ratingColors[2]
        },
        {
            label: frequencyRagtingWithLabels[3],
            color: ratingColors[3]
        },
        {
            label: frequencyRagtingWithLabels[4],
            color: ratingColors[4]
        },
        {
            label: "אין נתונים",
            color: ratingColors[5]
        },
    ]
}

export default constants