import AssessmentHooks from 'components/School/Assessments/hooks';
import TestTakerHooks from 'components/School/TestTakers/hooks'

const AdminAssessmentsData = () => {
    const idField = AssessmentHooks.useIdField()
    const nameField = TestTakerHooks.useNameField()

    const fields = [
        idField,
        {
            field: 'reading_assessment_name',
            headerName: "שם האבחון",
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        nameField.field,
      
    ]

    const headerBtns = []

    return {
        fields,
        headerBtns,
    }
}



export default AdminAssessmentsData
