import React, { useContext, useEffect, useRef, useState } from 'react';
import { CreateAssessmentRecordCrud, CreateAssessmentRecordData } from './hooks';
import { WrapFooterActions } from './Style'
import { Fab, Comment } from 'components/Form';
import { MicContext } from './AssessmentRecord';
import { makingAssessmentHooks, Play } from 'components/School/MakingRecordAssessments'
import { toast } from 'react-toastify';
import { toastConfig } from 'config';
import { useIdleTimer } from 'react-idle-timer';
import { useSearchParams } from 'react-router-dom';

const Mic = (props) => {
    const { customActions = {} } = props

    const toastId = useRef(null);
    const [searchParams] = useSearchParams();
    const assessmentID = searchParams.get('assessment');

    const { initMic, stop } = CreateAssessmentRecordCrud()
    const { isRecordingRef, audioURL, isPlaying, blobURL } = useContext(MicContext)
    const { actionFabs, clean, playRecord, recordFab, uploadFab, } = CreateAssessmentRecordData()

    const { micOff } = makingAssessmentHooks.constants

    const onIdle = () => {
        if (!isRecordingRef.current)
            toastId.current = toast(micOff, toastConfig)
    }

    const { start } = useIdleTimer({
        onIdle,
        startManually: true,
        stopOnIdle: true,
        timeout: 10_000,
    })

    useEffect(() => {
        start()
    }, [assessmentID])

    useEffect(() => {
        initMic()

        return (() => {
            // toast.dismiss(toastId.current)
            if (isRecordingRef.current)
                stop()
        })
    }, [])

    const uploadFabItem = customActions?.upload || uploadFab

    return (
        <>
            <WrapFooterActions align="start">
                {actionFabs.map((fab, index) =>
                    <Fab key={index} {...fab} />
                )}
            </WrapFooterActions>
            <WrapFooterActions align="center">
                <Fab {...clean} />
                <Fab {...playRecord} />
                <Fab {...recordFab} />
            </WrapFooterActions>
            <WrapFooterActions align="end">
                <Fab
                    {...uploadFabItem}
                    {...uploadFab}
                    onClick={() => {
                        uploadFabItem.onClick(blobURL)
                    }}
                    loading={uploadFabItem.loading}
                // icon={{
                //     icon: uploadFabItem.loading ? CircularProgress : uploadFabItem.icon?.icon,
                //     color: "disabled"
                // }}
                />
            </WrapFooterActions>
            <Play url={audioURL} isPlaying={isPlaying} />
        </>
    );
}

export default Mic;