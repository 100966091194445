import React, { useCallback, useEffect, useRef, useState } from "react";
import Title from 'components/Words/PageFlip/Title'
import Cover from 'components/Words/PageFlip/Cover'
import Page from "components/Words/PageFlip/Page";
import HTMLFlipBook from "react-pageflip";
import { isEmpty } from "lodash";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { BackAndNextButtons, WrapPageFlip } from './Style'

import './PageFlipSass.scss'
import { Comment } from "components/Form";

const CustomBook = (props) => {
    const { pages, title, comment } = props

    const flipBook = useRef()
    const book = useRef()

    const pageCount = book?.current?.pageFlip()?.getPageCount()
    const currentPageIndex = book?.current?.pageFlip()?.getCurrentPageIndex()

    const [currentPage, setCurrentPage] = useState(pages?.length || 0)


    const onFlip = useCallback((e) => {
        setCurrentPage(e.data)
    }, []);

    return (
        <WrapPageFlip>
            {!isEmpty(pages) &&
                <React.Fragment>
                    <BackAndNextButtons onClick={() => book.current.pageFlip().flipNext()}>
                        <ChevronRight className="icon" />
                    </BackAndNextButtons>
                    {(
                        !currentPage && 
                        comment) ?
                        <Comment right={420} title="לחצו על עמודי הספר בכדי לדפדף בין העמודים" open={true} placement={"right"}>
                            <button style={{ background: "transparent", border: "none" }}></button>
                        </Comment>
                        :
                        <></>}
                    <div style={{ width: "100%" }}>
                        {pages.length &&
                            <HTMLFlipBook
                                startPage={pages.length + 1}
                                width={203}
                                height={123}
                                autoSize={true}
                                onFlip={onFlip}
                                disableFlipByClick={false}
                                size="stretch"
                                minWidth={200}
                                maxWidth={610}
                                minHeight={120}
                                maxHeight={850}
                                maxShadowOpacity={0.5}
                                showCover={true}
                                mobileScrollSupport={true}
                                onChangeState={(e) => {
                                }}
                                onChangeOrientation={() => { }}
                                className="demo-book"
                                usePortrait
                                ref={book}
                            // ref={(el) => (flipBook.current = el)}
                            >
                                <Cover>
                                    {"סוֹף!"}
                                </Cover>


                                {pages?.map((page, index) =>
                                    <Page key={index} number={pages.length - index} firstPage={index == pages.length - 1} title={title}>
                                        {page.map((word) =>
                                            word
                                        )}
                                    </Page>
                                )}

                                <Cover firstPage>
                                    <Title>
                                        {title}
                                    </Title>
                                </Cover>
                            </HTMLFlipBook>
                        }
                    </div>
                    <BackAndNextButtons onClick={() => book.current.pageFlip().flipPrev()}>
                        <ChevronLeft className="icon" />
                    </BackAndNextButtons>
                </React.Fragment>
            }
        </WrapPageFlip>
    )
}

export default CustomBook
