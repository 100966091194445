import { systems } from "keys"


const useFields = () => {
    const fields = [
        {
            field: 'id',
            headerName: "ID",
            width: 150,
            headerAlign: 'start',
            align: 'start',
        },
        {
            field: 'username',
            headerName: "שם משתמש",
            width: 150,
            headerAlign: 'start',
            align: 'start',
        },
        // {
        //     field: 'password',
        //     headerName: "סיסמא",
        //     width: 150,
        //     headerAlign: 'start',
        //     align: 'start',
        // },
        {
            field: 'first_name',
            headerName: "שם",
            width: 150,
            headerAlign: 'start',
            align: 'start',
            valueGetter: ({ row }) => `${row.first_name} ${row.last_name}`
        },
        {
            field: 'email',
            headerName: "מייל",
            width: 150,
            headerAlign: 'start',
            align: 'start',
        },
        {
            field: "systems",
            headerName: "מערכת",
            width: 150,
            headerAlign: 'start',
            align: 'start',
            valueGetter: ({ value }) => value.map(item => systems[item])
        }
    ]

    return fields
}
export default useFields