import styled from "styled-components";
import { Header as HeaderDataGrid } from 'components/DataGrid/Style'
import { Scroll } from "styles/GlobalCss";

const WrapAssessmentHeader = styled.div`

`

const WrapAssessmentDetails = styled.div`
height:30px;
`

const Header = styled(HeaderDataGrid)`
width:100%;
display: flex;
overflow-y:hidden;
overflow-x:auto;
row-gap: 10px;
justify-content: flex-start;
padding:15px 0px;
// box-sizing:border-box;
${props => props.headercss && props.headercss};
${Scroll};
`

const WrapFiltersHeader = styled.div`
display:flex;
align-items:center;
height:${props => props.height ? `${props.height}px` : "70px"};
`

export { WrapFiltersHeader, Header, WrapAssessmentHeader, WrapAssessmentDetails }