

const useNormalDistributionFields = () => {

    const fields = [
        // {
        //     field: 'grade',
        //     headerName: "שכבה",
        //     width: 150,
        // },
        {
            field: 'score',
            headerName: "ציון",
            width: 150,
            valueGetter: ({ row }) => `${row.min_score}-${row.max_score}`
        },
        {
            field: 'count',
            headerName: "מספר התלמידים",
            width: 150,
        },
        {
            field: 'percentage',
            headerName: "אחוז התלמידים",
            width: 150,
            valueGetter: ({ value }) => `${value}%`
        }
    ]

    return fields
}

export default useNormalDistributionFields