
const constants = {
    omission: "השמטה",
    minorError: "שגיאה קלה",
    selfCorrection: "היסוס/חזרה",
    error: "שגיאה",
    errorWithSelfCorrection: "תיקון עצמי של שגיאה",
    minorErrorWithSelfCorrection: "תיקון עצמי של שגיאה קלה",
    omissionAndMinorError: "השמטה כשגיאה קלה",
    omissionAndError: "השמטה כשגיאה"
}

export default constants
