import React, { useContext, useEffect } from 'react'
import { WrapReports, WrapDetailesTestTaker, WrapTitle, WrapGradeGraph, WrapRatingSections } from './Style'
import { CustomSection, SpaceBetween, TestTakerName, Title, XSSpaceBetween } from 'styles/GlobalStyle'
import { Assessment, Page, Record } from 'components'
import { ChallengesTabs, ratingHooks } from '.'
import { ratingRQ } from 'store/hooks'
import { Chip, IconButtons } from 'components/Form'
import { RatingContext } from './RatingContext'
import { useTheme } from 'styles'
import { useSelector } from 'react-redux'
import { AssessmentWords } from 'components/School/Assessment'
import { thirdly } from 'styles/theme'
import { assessmentUtils } from 'helpers'
import { keyBy } from 'lodash'
import { semesters } from 'keys'

const Rating = () => {
    const { setReportDisplay, reportDisplay } = useContext(RatingContext)

    const { Layout } = useTheme()

    const { assessment } = useSelector(state => state.assessment)

    const { refetch: refetchRating } = ratingRQ.useGetRating()
    const reportDisplayBtns = ratingHooks.useReportDisplayBtns()

    const currentSemester = assessmentUtils.getSemester(assessment.create_date)
    const semestersKeys = keyBy(semesters, "id")
    const semester = semestersKeys?.[currentSemester]

    useEffect(() => {
        if (assessment.id)
            refetchRating()

        return (() => {
        })
    }, [assessment.id])

    return (
        <Page>
            <WrapReports>
                <div style={{ ...Layout.fullWidth, ...Layout.colHCenter, ...Layout.mrGap }}>
                    <div style={{ ...Layout.flex, ...Layout.justifyContentBetween }}>
                        <div style={{ ...Layout.flex }}>
                            {
                                assessment?.reading_assessment_abilities &&
                                <React.Fragment>
                                    {"היכולות שנבדקו:"}
                                    <XSSpaceBetween />
                                    {assessment?.reading_assessment_abilities?.map((abilty) =>
                                        <Chip borderColor="#FFB679">{abilty.label}</Chip>
                                    )}
                                </React.Fragment>
                            }
                        </div>
                        <IconButtons
                            btns={reportDisplayBtns}
                            setSelected={(e, val) => setReportDisplay(val)}
                            selected={reportDisplay}
                        />
                    </div>

                </div>

                <CustomSection $flex={false} $bg $shrink height={130}>
                    <Record />
                </CustomSection>
                {reportDisplay === 'report' || !reportDisplay ?
                    <ChallengesTabs />
                    :
                    <AssessmentWords />
                }
            </WrapReports>
        </Page>
    )
}

export default Rating