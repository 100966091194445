import { AlertCrud } from "components/Alerts/hooks"
import { alerts as alertKeys } from "keys"
import { useSelector } from "react-redux"
import { uploadAudioHooks } from ".."
import { useMutation } from "react-query"
import { saveAssessmentFetch, uploadAudioFileFetch } from "store/middlewares/assessment.crud"
import dayjs from "dayjs"
import { toast } from "react-toastify"
import { toastConfig } from "config"

const useSubmitBtns = () => {
    const { formname } = uploadAudioHooks.constants
    const { alerts } = useSelector(state => state.general)
    const formValues = useSelector(state => state.form[formname]?.values)
    const { setAlerts } = AlertCrud()

    const { mutateAsync: uploadFile, isLoading: uploadFileLoading } = useMutation(uploadAudioFileFetch)
    const { mutateAsync: saveAssessment, isLoading: saveAssessmentLoading } = useMutation(saveAssessmentFetch)

    const closeAlert = () => {
        setAlerts(alertKeys.uploadAudio, false)
    }

    const updateAssessment = async () => {
        const id = alerts[alertKeys.uploadAudio].props
        const { grade_of_allocation, file_uploaded_date = new Date(), file } = formValues
        await uploadFile({ file, id })

        const assessment = { id: id, file_uploaded_date: dayjs(file_uploaded_date).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ') }
        if (grade_of_allocation)
            assessment.grade_of_allocation = grade_of_allocation?.id
        await saveAssessment(assessment)
        toast("המבדק התעדכן בהצלחה", toastConfig)
        closeAlert()
    }

    const submitBtns = [
        {
            id: 0,
            label: "עדכון המבדק",
            action: updateAssessment,
            color: "secondary",
            loading: saveAssessmentLoading || uploadFileLoading
        },
        {
            id: 1,
            label: "ביטול",
            color: "thirdly",
            action: closeAlert
        }
    ]

    return submitBtns
}

export default useSubmitBtns