import React from 'react'
import { DataGrid } from 'components'
import { WrapDataGrid } from 'components/DataGrid/Style'
import userHooks from 'components/Admin/Users/hooks'
import { userRQ } from 'store/hooks'

const Users = () => {
    const fields = userHooks.useFields()

    const { data: users } = userRQ.useGetUsers()

    return (
        <WrapDataGrid>
            {/* <AssessmentFiltersHeader {...props} /> */}
            <DataGrid
                data={users}
                fields={fields}
            />
        </WrapDataGrid>
    )
}

export default Users
