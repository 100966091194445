import React from 'react'
import { Graphs } from '.'
import { getAccuracyStatsFetch, getFactoredAccuracyStatsFetch, getFluencyStatsFetch, getHesitationsStatsFetch } from 'store/middlewares/rating.crud'
import { useSelector } from 'react-redux'
import { queryClient as qc } from 'keys';
import { useQuery } from 'react-query';

const GraphsPage = (props) => {
    const { open, setOpen, assessmentCount } = props

    const { headerFilters } = useSelector(state => state.header)
    const { assessmentFilters, statusFilters } = useSelector(state => state.assessment)


    const { data: accuracyStats, refetch: refetchAccuracyStats } = useQuery({
        enabled: !!open,
        queryKey: qc.accuracyStats,
        queryFn: async () => {
            const res = await getAccuracyStatsFetch({ headerFilters, assessmentFilters, statusFilters })
            return res.data
        },
    })
    const { data: factoredAccuracyStats, refetch: refetchFactoredAccuracyStats } = useQuery({
        enabled: !!open,
        queryKey: qc.factoredAccuracyStats,
        queryFn: async () => {
            const res = await getFactoredAccuracyStatsFetch({ headerFilters, assessmentFilters, statusFilters })
            return res.data
        },
    })
    const { data: fluencyStats, refetch: refetchFluencyStats } = useQuery({
        enabled: !!open,
        queryKey: qc.fluencyStats,
        queryFn: async () => {
            const res = await getFluencyStatsFetch({ headerFilters, assessmentFilters, statusFilters })
            return res.data
        },
    })
    const { data: hesitationsStats, refetch: refetchHesitationsStats } = useQuery({
        enabled: !!open,
        queryKey: qc.hesitationsStats,
        queryFn: async () => {
            const res = await getHesitationsStatsFetch({ headerFilters, assessmentFilters, statusFilters })
            return res.data
        },
    })

    const commonState = {
        accuracyStats, refetchAccuracyStats,
        factoredAccuracyStats, refetchFactoredAccuracyStats,
        fluencyStats, refetchFluencyStats,
        hesitationsStats, refetchHesitationsStats
    }

    return (
        <GraphsContext.Provider value={commonState}>
            <Graphs open={open} setOpen={setOpen} {...props}/>
        </GraphsContext.Provider>
    )

}

export const GraphsContext = React.createContext()
export default GraphsPage