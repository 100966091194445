import dayjs from "dayjs"

class Coupon {

    constructor(coupon) {
        const { comment, expiration_date, is_open, price, name } = coupon
        this.name = name
        this.is_open = is_open || false
        this.price = price.id
        this.comment = comment
        this.expiration_date = expiration_date ? dayjs(expiration_date).format('YYYY-MM-DD') : null
    }
}

export default Coupon