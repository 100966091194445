import AudioPlayer from "components/Form/Audio"
import { WordCell, IDSCell, PlayCell } from ".."

const useFields = () => {

    const fields = [


        {
            field: 'type',
            headerName: "מילה",
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: WordCell,
        },
        {
            field: 'count',
            headerName: "מספר",
            width: 80,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'percent',
            headerName: "אחוז",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value ? `${value.toFixed(1)}%` : "-"
        },
        {
            field: "mic",
            headerName: "נגן",
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: PlayCell,
            props: {
                width: "250px",
                height: "35px",
            },
        },
        {
            field: "IDS",
            headerName: "ID",
            width: 130,
            headerAlign: 'center',
            align: 'center',
            renderCell: IDSCell,
        }
    ]

    return fields
}

export default useFields