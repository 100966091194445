import React, { useContext } from 'react'
import { useTheme } from 'styles'
import { labHooks } from '.'
import { WordWithFrequency } from './Style'
import { ratingList } from 'keys'
import { Tooltip } from '@mui/material'
import { ScrollBox } from 'styles/GlobalStyle'
import { LinguisticLabContext } from './LinguisticLabContext'

const WordDefsWithFrequency = (props) => {
    const { word_defs } = props

    const { setSelectedWordDef, setOpenWordDrawer } = useContext(LinguisticLabContext)

    const { Layout } = useTheme()
    const { frequencyItems } = labHooks.constants

    const selectWordDef = (word) => {
        setSelectedWordDef(word)
        setOpenWordDrawer(true)
    }

    return (
        <ScrollBox>
            <div style={{
                flexWrap: "wrap", ...Layout.lcGap, ...Layout.lrGap, ...Layout.flex
            }}>
                {word_defs?.map((word) =>
                    <Tooltip title={frequencyItems[word.frequency_index].label} placement='top'>
                        <WordWithFrequency onClick={() => selectWordDef(word)} color={ratingList[word.frequency_index]} style={{ height: '30px', width: "80px", ...Layout.rowCenter }}>
                            {word.word_def_text}
                        </WordWithFrequency>
                    </Tooltip>
                )}
            </div>
        </ScrollBox>
    )
}

export default WordDefsWithFrequency