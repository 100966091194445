import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AssessmentDefs, Page } from 'components'
import { MatchingLearningCrud, MatchingLearningData } from 'components/School/MatchingLearning/hooks'
import { SubTitle } from 'styles/GlobalStyle'
import { constants } from './hooks'
import { useNavigate } from 'react-router-dom'
import { app } from 'components/Router/routes'
import { ratingRQ } from 'store/hooks'

const MatchingLearning = () => {
    const { cleanData } = MatchingLearningCrud()
    const { headerActions } = MatchingLearningData()
    const navigate = useNavigate()

    const { } = ratingRQ.useGetRating()
    const { data: matchingLearning } = ratingRQ.useGetMatchingLearning()

    useEffect(() => {
        return (() => {
            cleanData()
        })
    }, [])

    const goAssessmentDef = ({ id }) => navigate(`${app.assessmentDefs}/${id}`)

    return (
        <Page>
            <SubTitle>{constants.title} </SubTitle>
            <AssessmentDefs
                headerActions={headerActions}
                onSelectAssessment={goAssessmentDef}
                assessments={matchingLearning || []}
                cleanData={cleanData} />
        </Page>
    )

}

export default MatchingLearning