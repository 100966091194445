import styled from "styled-components";
import { thirdly2 } from "styles/theme";
import { thirdly } from "styles/theme";
import { white, header, sidebar } from "styles/theme";


const WrapHeader = styled.div`
height:${header - 20}px;
padding:10px;
background:${white};
position: absolute;
top: 0;
width: 100%;
border-bottom:1px solid ${thirdly2};
`

const WrapPullDown = styled.div`
`

const WrapContent = styled.div`
margin-right:${sidebar + 10}px;
display:flex;
column-gap: 15px;
align-items:center;
`

export { WrapHeader, WrapPullDown, WrapContent }