import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AssessmentDefCrud, AssessmentDefData } from './hooks'
import { Page, Words } from 'components'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actions } from 'store'
import { wordsRQ } from 'store/hooks'
import { isBoolean } from 'lodash'

const AssessmentDef = (props) => {
    const { hideBackBtn, student, hidePoints, customHeader, comment } = props
    const { wordDefs = {} } = useSelector(state => state.words)
    const { cleanAssessmentDef, } = AssessmentDefCrud()
    const { headerActions } = AssessmentDefData()

    const { refetch } = wordsRQ.useGetWordDefs()

    const { visible_type, name, word_defs, points } = wordDefs || {}

    const dispatch = useDispatch()

    const [searchParams] = useSearchParams();

    const assessmentDefID = searchParams.get('assessmentDef');
    const testTakerID = searchParams.get('testTaker');

    useEffect(() => {
        if (assessmentDefID)
            refetch()
    }, [assessmentDefID])

    useEffect(() => {
        dispatch(actions.updateAssessment({ reading_assessment_def_id: assessmentDefID }))
        dispatch(actions.editTestTaker({ test_taker_id: testTakerID }))

        return (() => {
            cleanAssessmentDef()
        })
    }, [])

    return (
        <Page>
            <Words
                print={true}
                type={visible_type}
                name={name}
                words={word_defs}
                points={points}
                comment={comment}
                student={isBoolean(student) ? student : true}
                showErrorTypes={false}
                hidePoints={hidePoints}
                hideBackBtn={isBoolean(hideBackBtn) ? hideBackBtn : true}
                headerActions={headerActions}
                customHeader={customHeader}
            />
        </Page>
    )
}

export default AssessmentDef