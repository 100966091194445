import React, { useEffect } from 'react';
import { TestTakersCrud, useGenerateCouponCode } from './hooks';
import { useSelector } from 'react-redux';
import { Header, WrapDataGrid } from 'components/DataGrid/Style';
import { Button } from 'components/Form';
import { DataGrid } from 'components';
import { dataGridModel } from 'keys';
import { WrapHeader } from 'styles/GlobalStyle'
import { testTakerRQ } from 'store/hooks';
import { testTakerHooks } from '.'

const TestTakers = () => {
    const { setRowSelectionModel } = TestTakersCrud()
    const { headerFilters } = useSelector(state => state.header)

    const generateCouponCodeBtn = useGenerateCouponCode()

    const fields = testTakerHooks.useTestTakerfields()
    const headerBtns = testTakerHooks.useHeaderBtns()

    const { refetch: refetchGetWithLastAssessments, remove: removeGetWithLastAssessments, data } = testTakerRQ.useGetWithLastAssessments()

    useEffect(() => {
        removeGetWithLastAssessments()
        refetchGetWithLastAssessments()
    }, [headerFilters])

    return (
        <WrapDataGrid>
            <WrapHeader>
                <Header>
                    {[...headerBtns, generateCouponCodeBtn].map((headerBtn, index) =>
                        <Button key={index} {...headerBtn} />
                    )}
                </Header>
            </WrapHeader>
            <DataGrid
                rowId={"test_taker_id"}
                data={data?.data}
                fields={fields}
                checkboxSelection
                visibilityModelKey={dataGridModel.last_assessments}
                onRowSelectionModelChange={setRowSelectionModel}
            />
        </WrapDataGrid>
    );
}

export default TestTakers
