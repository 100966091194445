import React, { useContext, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { WrapGraph, WrapIcon, WrapNumChild, WrapGraphTexts, GraphNumber, WrapGraphItem, WrapChild } from './Style'
import { firstWord } from 'utils/obj'
import { ReactComponent as ChildIcon } from 'assets/icons/Child.svg';
import { Progress } from 'components/Record'
import { FlexCenter } from 'styles/GlobalStyle'
import { isEmpty } from 'lodash'
import ratingList from 'keys/rating'
import { ArrowRightAlt, ChevronLeft } from '@mui/icons-material'

const GradeGraph = (props) => {
    const { graphText, text, viewPercent, fixed, scoreRating, showAllChildren, stats, percent: graphPercent, disabled } = props
    const { testTaker = {} } = useSelector(state => state.testTaker)

    return (
        <div style={{
            display: "flex",
            overflow: "auto"
        }}>
            {graphText &&
                <WrapGraphTexts>
                    <div className='wrap-graph-text'>
                        <div className='graph-text'>
                            {text}
                        </div>
                        <ArrowRightAlt className='graph-icon' />
                    </div>
                    <div className='wrap-graph-text'>
                        <div className='graph-text'>
                            {graphText}
                        </div>
                        <ArrowRightAlt className='graph-icon' />
                    </div>
                </WrapGraphTexts>
            }
            <WrapGraph disabled={disabled}>

                {!isEmpty(stats) ? Object.keys(stats).map((graphNumber, index) => {
                    const item = stats[graphNumber]

                    const { hide, rating_id, rating_index, percent, count, current_test_taker } = item

                    const theme = {
                        color: scoreRating ? scoreRating?.[rating_id]?.color : ratingList[rating_index - 1],
                        width: fixed >= 1 ? "40px" : "33px"
                    }

                    return (
                        <ThemeProvider theme={theme} key={index}>
                            <WrapGraphItem current_test_taker={current_test_taker}>
                                <WrapNumChild>
                                    {!hide &&
                                        viewPercent ? (percent && `${percent.toFixed(1)}%`) :
                                        current_test_taker ?
                                            firstWord(testTaker.test_taker_name) :
                                            count > 1 && showAllChildren && (`+${count}`)}
                                </WrapNumChild>
                                <WrapChild>

                                    {!hide &&
                                        ((showAllChildren || current_test_taker) && count >= 1 &&
                                            <WrapIcon fontSize="small" component={ChildIcon} inheritViewBox />)}
                                </WrapChild>
                                <GraphNumber>
                                    {!hide &&
                                        <React.Fragment>
                                            {fixed ? Number(graphNumber)?.toFixed(fixed) : graphNumber}
                                            {graphPercent && "%"}
                                        </React.Fragment>}
                                </GraphNumber>

                            </WrapGraphItem>
                        </ThemeProvider>
                    )
                }
                ) :
                    <FlexCenter>
                        <Progress />
                    </FlexCenter>
                }

            </WrapGraph>
        </div >
    )
}

export default GradeGraph