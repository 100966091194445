import WordsData from './Words.data'
import useAssessmentCategory from './useAssessmentCategory'
import useWordInsertion from './useWordInsertion'
import useTimePoint from './useTimePoint'
import useEnd from './useEnd'
import useOmission from './useOmission'
import useError from './useError'
import useRepitition from './useRepitition'

export { WordsData }
export default { useError, useRepitition, useOmission, useEnd, useAssessmentCategory, useWordInsertion, useTimePoint }