
const useIdField = () => {
    const isField = {
        field: 'id',
        headerName: "ID",
        width: 80,
        align: 'center',
        headerAlign: "center",
        // feature: "col_ID",
        // role: "read",   
    }
    return isField
}

export default useIdField