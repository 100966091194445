import { types, updateType } from "keys"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions } from "store"


const useOmission = () => {
    const dispatch = useDispatch()
    const { words } = useSelector(state => state.words)

    const removeWord = (index) => {
        let nWord = { ...words[index] }
        nWord.removed = !nWord.removed
        // if (nWord.removed)
        //     nWord.status = types.incorrect
        // else
        //     nWord.status = types.correct
        // nWord.minorError = false
        nWord.customStatus = false
        nWord.update_type = updateType.update
        dispatch(actions.updateSingleWord({ index: index, word: nWord }))
    }

    return removeWord
}

export default useOmission