

import en from 'config/strings'

const validate = (values) => {
    const errors = {}

    if (values) {
        if (!values.file) {
            errors.file = en.require()
        }
        return errors;
    }
}

export default validate
