import React from 'react'
import { TestTakerName, XSSpaceBetween } from 'styles/GlobalStyle'
import { Chip } from 'components/Form'
import { WrapAssessmentDetails } from './Style'
import { useTheme } from 'styles'
import { useSelector } from 'react-redux'
import { thirdly } from 'styles/theme'
import { assessmentUtils } from 'helpers'
import { keyBy } from 'lodash'
import { semesters } from 'keys'

const AssessmentDetails = (props) => {
    const { actions } = props

    const { Layout } = useTheme()

    const { assessment } = useSelector(state => state.assessment)
    const { testTaker } = useSelector(state => state.testTaker)

    const currentSemester = assessmentUtils.getSemester(assessment.create_date)
    const semestersKeys = keyBy(semesters, "id")
    const semester = semestersKeys?.[currentSemester]

    return (
        <WrapAssessmentDetails>
            <div style={{ ...Layout.fullWidth, ...Layout.colHCenter, ...Layout.mrGap }}>
                <div style={{ ...Layout.flex }}>
                    {testTaker?.test_taker_name &&
                        <React.Fragment>
                            <TestTakerName>
                                {testTaker?.test_taker_name}
                            </TestTakerName>
                            <XSSpaceBetween />
                            <div style={{ ...Layout.flex }}>
                                {assessment.reading_assessment_grade_of_allocation && <Chip borderColor={thirdly}>{assessment.reading_assessment_grade_of_allocation_name}</Chip>}
                                <Chip borderColor={thirdly}>{semester.name}</Chip>
                                {assessment.reading_assessment_category && <Chip borderColor={thirdly}>{assessment.reading_assessment_category}</Chip>}
                            </div>
                        </React.Fragment>
                    }
                </div>
                {/* <div style={{ ...Layout.flex, ...Layout.justifyContentBetween }}>
                    <div style={{ ...Layout.flex }}>
                        {
                            assessment?.reading_assessment_abilities &&
                            <React.Fragment>
                                {"היכולות שנבדקו:"}
                                <XSSpaceBetween />
                                {assessment?.reading_assessment_abilities?.map((abilty) =>
                                    <Chip borderColor="#FFB679">{abilty.label}</Chip>
                                )}
                            </React.Fragment>
                        }
                    </div>
                    {actions}
                </div> */}

            </div>
        </WrapAssessmentDetails>
    )
}

export default AssessmentDetails
