import { ReactComponent as ViewAssessmentIcon } from 'assets/icons/ViewAssessment.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { app } from 'components/Router/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useReportDisplayBtns = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { assessment } = useSelector(state => state.assessment)
    const assessmentID = assessment.id || params?.assessmentID


    const goAssessmentView = () => {
        navigate(`${app.assessmentView}/${assessmentID}`)
    }

    const btns = [
        {
            key: "report",
            icon: ReportIcon,
            action: () => { },
            fontSize: "small"
        },
        {
            key: "assessmentView",
            icon: ViewAssessmentIcon,
            action: () => { },
            fontSize: "small"
        }
    ]

    return btns
}

export default useReportDisplayBtns