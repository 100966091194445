import React, { useState } from 'react';
// import { errorHooks } from '.';
import { Box } from '@mui/material';
import { Edit, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material';
import { useTheme } from 'styles';
import { assign } from 'lodash'
import { Dial } from 'components/Words/ErrorTypes/Styled';
import { DialAction } from './Style';

const LevelsDial = (props) => {
    const { buttonSize, levels, label, iconOpen = KeyboardArrowLeft, iconClose = KeyboardArrowRight } = props

    const [openDial, setOpenDial] = useState(false)

    const { Layout } = useTheme()

    // const errorTypeList = errorHooks.useErrorTypeList()
    const Icon = openDial ? iconClose : iconOpen

    return (
        <Box sx={{ height: 50, transform: 'translateZ(0px)', flexShrink: 0 }}>
            <Dial
                {...buttonSize}
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 0 }}
                icon={
                    <div onClick={() => setOpenDial(!openDial)} style={assign(Layout.rowCenter, Layout.scGap)}>
                        {label}
                        <Icon color='thirdly2' openIcon={<Edit />} />
                    </div>
                }
                open={openDial}
                direction='right'
            >
                {levels.map((level, index) => (
                    <DialAction
                        {...level}
                        sx={{ pointerEvents: "none" }}
                        aria-label='dsdsa'
                        key={index}
                        icon={level.label}
                    />
                ))}
            </Dial>
        </Box>
    );
}

export default LevelsDial
