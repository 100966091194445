import { useNavigate } from "react-router-dom"
import { paymentHooks } from ".."
import { app } from "components/Router/routes"
import { AlertCrud } from "components/Alerts/hooks"
import { alerts as alertKeys } from "keys"
import { actions } from "store"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { secretStorage } from "config"


const useRepeatPurchaseBtn = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { alerts } = useSelector(state => state.general)

    const goPayments = () => {
        navigate(app.payments)
        secretStorage.setItem("registerForm", { test_taker_id: alerts[alertKeys.noBalance].props.test_taker_id })
        dispatch(actions.setAlerts({
            key: alertKeys.noBalance,
            value: { open: false }
        }))
    }

    const repeatPurchaseBtn = {
        label: paymentHooks.constants.noBalanceBtn,
        action: goPayments,
        color: "secondary",
        disabled: true
    }

    return repeatPurchaseBtn
}

const useNoBalanceAlert = () => {
    const { setAlerts } = AlertCrud()

    return (val) => {
        setAlerts(alertKeys.noBalance, val)
    }
}

export { useRepeatPurchaseBtn, useNoBalanceAlert }