import { ReactComponent as ViewAssessmentIcon } from 'assets/icons/ViewAssessment.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/Report.svg';
import { graphsHooks } from '..';

const useDifferentGraphViewsBtns = () => {
    const { graphViews } = graphsHooks.constants

    const btns = [
        {
            key: graphViews.graph,
            icon: ReportIcon,
            action: () => { }
        },
        {
            key: graphViews.table,
            icon: ViewAssessmentIcon,
            action: () => { }
        }
    ]

    return btns
}

export default useDifferentGraphViewsBtns