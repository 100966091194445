import React, { useCallback, useContext, useState } from 'react';
import { Drawer } from '@mui/material';
import { IconButtons } from 'components/Form'
import { DataGrid, graphsHooks } from '.';
import { useTheme } from 'styles';
import { GraphsContext } from './GraphsContext';
import { DrawerGraphStyles } from './Style'
import { GradeGraph } from 'components/School/Rating';
import { useQuery } from 'react-query';
import { getAccuracyStatsFetch } from 'store/middlewares/rating.crud';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';

const Graphs = (props) => {
    const { setOpen, open, wordDefCount } = props

    const distributionTypeBtns = graphsHooks.useDistributionTypeBtns()

    const [selected, setSelected] = useState(distributionTypeBtns[0].key)
    const [graphView, setGraphView] = useState()

    const { Layout, Gutters } = useTheme()

    const viewTypeBtnKeys = keyBy(distributionTypeBtns, "key")

    const differentGraphViewsBtns = graphsHooks.useDifferentGraphViewsBtns()
    const { graphViews } = graphsHooks.constants

    const changeDistributionType = useCallback((e, key) => {
        setSelected(key)
    }, [])

    return (
        <DrawerGraphStyles
            anchor={"bottom"}
            open={open}
            onClose={() => setOpen(false)}
        >
            <div className='wrap-graph-content'>
                <div className='icon-buttons'>
                    <IconButtons
                        btns={distributionTypeBtns}
                        setSelected={changeDistributionType}
                        selected={selected}
                        variant={"outlined"}
                    />
                    <div className='assessment-count'>
                        <div>{"מספר מילים במבדק"}</div>
                        <div>{wordDefCount}</div>
                    </div>
                </div>


                <div>
                    <GradeGraph
                        showAllChildren
                        disabled={false}
                        viewPercent
                        type={selected}
                        {...viewTypeBtnKeys[selected]}
                    />
                </div>
            </div>
        </DrawerGraphStyles >
    );
};

export default Graphs;