import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';
import { dark, thirdly } from "styles/theme";
import { Boy } from '@mui/icons-material';
import { Center, Scroll } from "styles/GlobalCss";
import { white } from "styles/theme";
import { Accordion, Button, SvgIcon, Tabs } from "@mui/material";
import { thirdly2 } from "styles/theme";

const graphH = '33px'

const RateAndAccuracyDescription = styled.div`
`

const WrapGraphTexts = styled.div`
display:flex;
flex-direction: column;
width: 145px;
justify-content: space-around;
font-size:15px;

.wrap-graph-text{
display: flex;
column-gap:5px;
align-items: center;
}

.graph-text{
}

.graph-icon{
transform:rotate(180deg);
}
`


const WrapAccordion = muiStyled(Accordion)(({ theme }) => (
    {
        '&.MuiAccordion-root': {
            boxShadow: "none",
            borderBottom: `1px solid ${thirdly2}`,
            position: "unset",
        },
        '&.MuiAccordionSummary-root': {
            height: "100px !important",
        },
        fontSize: '16px',
        '.tab-title': {
            display: "flex",
            alignItems: "center",
            columnGap: "10px"
        }
    }))

const WrapTabs = muiStyled(Tabs)(({ theme }) => (
    {
        '& .Mui-selected': {
            color: `${dark} !important`,
            fontWeight: "bold"
        }
    }))

const RateAndAccuracyLabel = styled.div`
background-color:${white};
border-radius:7px;
padding:5px;
`

const GraphDisplay = styled(Button)`
width:30px !important;
min-width: 30px !important;
border-radius:5px !important;
`

const RateAndAccuracyItem = styled.div`
${Scroll};
`

const WrapReports = styled.div`
display: flex;
row-gap: 10px;
flex-direction: column;
height:100%;
flex-grow: 1;
overflow: auto;
font-size:16px;
`

const WrapTitle = styled.div`
font-size:18px;
`

const WrapChallengeItem = styled.div`
width:100%
`

const WrapChallenges = styled.div`
display: flex;
width: 100%;
padding-left:3px;
row-gap: 10px;
flex-direction: column;
overflow:auto;
${Scroll};

._in_soon{
padding-right:5px;
}
`

const WrapNumChild = styled.div`
font-size:11px;
text-align: center;
height:18px;
width:${props => props.theme.width};
color:${props => props.theme.color};
// text-shadow: ${props => `0.5px 0.5px 0.5px ${props.theme.color}`};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
direction: rtl;
padding: 4px 2px;
box-sizing: border-box;
`

const WrapIcon = styled(SvgIcon)`
width:33px !important;
height:30px !important;
path{
fill:${props => props.theme.color} !important;
}
`

const WrapGraphItem = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
align-items: center;
width:${props => props.theme.width};
${props => props.current_test_taker && `
border-radius:8px;
border:1px solid ${thirdly};
border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;
`} 
`

const WrapChild = styled.div`
height:37px;
width:${props => props.theme.width};
${Center};

`

const Number = styled.div`

`

const WrapGradeGraph = styled.div`

`

const HeaderSummary = styled.div`

`

const BoldSummary = styled.span`
color:${props => props.color};
`

const WrapDetailesTestTaker = styled.div`
display: flex;
height:70px;
width: 100%;
column-gap: 10px;
`

const WrapSelectRating = styled.span`
`

const WrapRating = styled.div`
display: flex;
row-gap: 8px;
flex-direction: column;
color: white;
`

const WrapSummaryData = styled.div`

`

const Rate = styled.div`
width:100%;
box-sizing:border-box;
background:${props => props.color};
border-radius: 2px;
padding:5px;
font-weight:400;
font-size:15px;
`

const WrapContentSection = styled.div`
display: flex;
height: 100%;
flex-direction: column;
justify-content: space-between;
`

const WrapActions = styled.div`
display: flex;
row-gap: 10px;
flex-direction: column;
`

const WrapRatingSections = styled.div`
display: flex;
column-gap: 10px;
flex-grow:1;
overflow: auto;
${Scroll};
`

const WrapRate = styled.div`
font-size:20px;
display:flex;
column-gap: 5px;
align-items:center;
`

const WrapAccuracy = styled.div`
font-size:20px;

`

const WrapSummary = styled.div`
padding:15px;
font-size:22px;
line-height: 40px;
display: flex;
flex-direction: column;
row-gap: 10px;
width: 100%;
box-sizing: border-box;
height:100;
overflow:auto;
${Scroll};
`

const AssessmentDetails = styled.div`

`

const Summary = styled.div`

`

const GraphNumber = styled.span`
height: 35px;
color:${props => props.theme.color};
width: 100%;
background:${white};
display: flex;
justify-content: center;
align-items:center;
font-size:12px;

`


const WrapGraph = styled.div`
opacity:${props => props.disabled && "0.5"};
overflow-x:auto;
flex-grow:1;
overflow-y:hidden;
display:flex;
// display: grid;
// grid-template-columns: repeat(auto-fill, 23px);
direction: ltr;
justify-content: space-between;
height:95px;
align-items: center;
width:100%;

${Scroll};
`



export { RateAndAccuracyLabel, WrapChallengeItem, WrapGraphTexts, WrapTitle, WrapTabs, RateAndAccuracyDescription, WrapAccordion, GraphDisplay, RateAndAccuracyItem, WrapChallenges, WrapNumChild, WrapIcon, WrapChild, WrapGraphItem, WrapGraph, GraphNumber, WrapGradeGraph, HeaderSummary, BoldSummary, WrapDetailesTestTaker, WrapSelectRating, WrapContentSection, WrapActions, WrapRating, Rate, Summary, WrapSummaryData, WrapRatingSections, Number, WrapRate, WrapAccuracy, WrapReports, WrapSummary, AssessmentDetails }