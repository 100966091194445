import { Pause, PlayArrow } from '@mui/icons-material';
import React, { useRef, useState } from 'react';
import WordWithError from 'records/WordWithError.mp4'


const CustomAudioPlayer = () => {
    const [play, setPlay] = useState(false)
    const audioRef = useRef(null);

    const handlePlayPause = () => {
        const audio = audioRef.current;
        if (audio.paused) {
            audio.play();
            setPlay(true)
        } else {
            audio.pause();
            setPlay(false)
        }
    };

    return (
        <div>
            <audio ref={audioRef} src={WordWithError} type="audio/mpeg" />
            {play ? <Pause onClick={handlePlayPause} /> : <PlayArrow onClick={handlePlayPause} />}
            {/* <button onClick={handlePlayPause}>
                {audioRef.current && !audioRef.current.paused ? 'Pause' : 'Play'}
            </button> */}
        </div>
    );
};

export default CustomAudioPlayer;