import styled from "styled-components";
import { Header as HeaderDataGrid } from 'components/DataGrid/Style'
import { Button } from "@mui/material";
import { lightPrimary, lightBlue2 } from "styles/theme";
import { filter } from "styles/constant";
import { WrapHeader } from "styles/GlobalStyle";
import { Scroll } from "styles/GlobalCss";

const Header = styled(HeaderDataGrid)`
width:100%;
display: flex;
overflow-y:hidden;
overflow-x:auto;
row-gap: 10px;
justify-content: flex-start;
padding:15px 0px;
// box-sizing:border-box;
${props => props.headercss && props.headercss};
${Scroll};
`

const WrapFiltersHeader = styled.div`
display:flex;
// flex-shrink: 0;
// column-gap: 10px;
align-items:center;
// width:100%;
// @media (max-width: 1339){
// height:${props => props.height ? `${props.height}px` : "110px"};
// }
height:${props => props.height ? `${props.height}px` : "70px"};
`

const IndicationLabel = styled.div`
color:${props => props.color};
`

const WrapBasicButton = styled(Button)`
box-shadow: none !important;
border:1px solid ${lightPrimary} !important;
`

const WrapFullButton = styled(Button)`
box-shadow: none !important;
border:1px solid ${lightBlue2} !important;
`

const WrapIndication = styled.div`
.MuiSvgIcon{
    color:pink !important
}
`

export { WrapFiltersHeader, Header, WrapIndication, IndicationLabel, WrapBasicButton, WrapFullButton }