import { useContext } from "react"
import { graphsHooks } from ".."
import { GraphsContext } from "../GraphsContext"

const useDistributionTypeBtns = () => {
    const { types } = graphsHooks.constants

    const {
        accuracyStats, refetchAccuracyStats,
        factoredAccuracyStats, refetchFactoredAccuracyStats,
        fluencyStats, refetchFluencyStats,
        hesitationsStats, refetchHesitationsStats
    } = useContext(GraphsContext)

    const btns = [
        {
            key: types.percent_accuracy,
            label: "דיוק לא משוקלל",
            action: () => { },
            percent: true,
            stats: accuracyStats,
            refetch: refetchAccuracyStats,
            text: "אחוז התלמידים",
            graphText: "אחוז הדיוק"
        },
        {
            key: types.factored_percent_accuracy,
            label: "דיוק משוקלל",
            action: () => { },
            percent: true,
            stats: factoredAccuracyStats,
            refetch: refetchFactoredAccuracyStats,
            text: "אחוז התלמידים",
            graphText: "אחוז הדיוק"
        },
        {
            key: types.num_words_per_minute,
            label: "מספר מילים לדקה",
            action: () => { },
            stats: fluencyStats,
            refetch: refetchFluencyStats,
            text: "אחוז התלמידים",
            graphText: "מספר מילים לדקה"
        },
        {
            key: types.repetition_percent,
            label: "רצף הקריאה",
            action: () => { },
            stats: hesitationsStats,
            refetch: refetchHesitationsStats,
            percent: true,
            fixed: 1,
            text: "אחוז התלמידים",
            graphText: "אחוז הדיוק"
        }
        // {
        //     key: types.correct_words_per_minute,
        //     label: "מספר מילים נכונות לדקה",
        //     action: () => { }
        // },
        // {
        //     key: types.repetition_percent,
        //     label: " חזרות",
        //     action: () => { }
        // },
        // {
        //     key: types.self_correction_percent,
        //     label: " תיקונים עצמיים",
        //     action: () => { }
        // },
        // {
        //     key: types.self_correction_and_minor_error_percent,
        //     label: " תיקונים עצמיים עם שגיאה קלה",
        //     action: () => { }
        // }
    ]


    return btns
}

export default useDistributionTypeBtns