import { testTakerRQ } from "store/hooks"



const useFields = () => {
    const { data: grades } = testTakerRQ.useGetAllGrades()


    const fields = [
        {
            name: "file",
            component: "uploadFile",
            label:"מבדק"
        },
        {
            name: "file_uploaded_date",
            placeholder: "מועד ביצוע המבדק",
            component: "datePicker",
            systemUI: true,
        },
        {
            name: 'grade_of_allocation',
            component: "autoComplete",
            placeholder: "שכבה בעת ביצוע",
            options: grades,
            systemUI: true,
        },
     
    ]

    return fields
}

export default useFields