import React from 'react'
import { Dashboard, WrapCartoon, WrapDashboard, WrapActionButton, Video, WrapVideo, WrapHeader, WrapContent, WrapTitle, Title } from './Style'
import { constants as c, useDashboardCards } from './hooks'
import { Button, Card } from 'components/Form'
import { CustomSection, Img } from 'styles/GlobalStyle'
import { Go4 } from 'assets'
import { CircularProgress } from '@mui/material'
import ReactPlayer from 'react-player'
import './style.css'
import { thirdly2 } from 'styles/theme'

const DashboardPage = () => {
    const cards = useDashboardCards()

    const handleFullScreen = () => {
        const player = document.querySelector('video');
        if (player) {
            player.requestFullscreen();
        }
    };

    return (
        <WrapDashboard>
            <Dashboard>
                <WrapHeader>
                    <WrapTitle>
                        <Title>
                            {c.title}
                        </Title>
                    </WrapTitle>
                    <WrapVideo>
                        <WrapCartoon height={400} width={330}>
                            <Img src={Go4} />
                        </WrapCartoon>
                        <CustomSection $noPadding zindex={1} $bg $systemUI width={700} height={400}>
                            <ReactPlayer
                                onStart={handleFullScreen}
                                className="camera_video_discover video"
                                url={require('../../../records/Dashboard.mp4')}
                                width='100%'
                                height='100%'
                                controls={true}

                            />
                        </CustomSection>
                    </WrapVideo>
                </WrapHeader>
                <WrapContent>
                    {cards.map((card) => {
                        const { label, button, loading } = card
                        return (
                            <CustomSection $border={thirdly2} $noShadow $textCenter justify="space-around" $column $center $systemUI $bg width={"24%"} height={200}>
                                {loading ?
                                    <CircularProgress color="gray" size={30} /> : label}
                                <WrapActionButton>
                                    <Button fullWidth systemUI {...button} />
                                </WrapActionButton>
                            </CustomSection>
                        )
                    })}
                </WrapContent>
            </Dashboard>
        </WrapDashboard>
    )
}

export default DashboardPage