import React from 'react';
import { CircularProgress, Fab, Tooltip } from '@mui/material';
import { WrapFab } from './Styled'
import { isEmpty } from 'lodash';
import { Comment } from 'components/Form'

const CustomFab = (props) => {
    const { icon, label, loading } = props

    return (
        <WrapFab disabled={loading} {...props}>
            {loading ? <CircularProgress color='dark' /> :
                <>
                    {label && label}
                    {!isEmpty(icon?.icon) && <icon.icon color={loading ? "disabled" : icon.color} />}
                </>
            }
        </WrapFab>
    );
}

const CustomFabWithComment = (props) => {
    const { comment, title, disabled, component, icon, label, loading } = props

    return (
        component ?
            <props.component
                {...props} disabled={disabled} /> :
            <Comment {...comment}>
                <WrapFab disabled={loading} {...props}>
                    {loading ? <CircularProgress color='dark' /> :
                        <>
                            {label && label}
                            {!isEmpty(icon?.icon) && <icon.icon color={loading ? "disabled" : icon.color} />}
                        </>
                    }
                </WrapFab>
            </Comment>
    )
}

export default CustomFabWithComment;