import { AssessmentsAndTestTakersData } from 'components/School/AssessmentsAndTestTakers/hooks';
import { AssessmentStatusCell } from 'components/School/PerformAssessments'
import { timeAgoWithOutSeconds } from 'utils/formatter';
import { alerts, statuses } from 'keys'
import { AllAssessmentsCrud } from '.';
import { IconButtonGroup } from 'components/Form';
import { AlertCrud } from 'components/Alerts/hooks';
import { useDispatch } from 'react-redux';
import { actions } from 'store';
import { ReactComponent as GoIcon } from 'assets/icons/Go.svg';
import { ReactComponent as GoDisabledIcon } from 'assets/icons/GoDisabled.svg';
import { ReactComponent as UploadFileIcon } from 'assets/icons/UploadFile.svg';
import { testTakerHooks } from 'components/School/TestTakers'
import { assessmentsHooks } from 'components/School/Assessments';

const useFields = () => {
    const dispatch = useDispatch()
    const { setAlerts } = AlertCrud()
    const { playField } = AssessmentsAndTestTakersData()
    const { goRecord } = AllAssessmentsCrud()

    const nameField = testTakerHooks.useNameField()
    const gradeOfAllocation = assessmentsHooks.useGradeOfAllocation()

    const openUploadAudioPopup = (id) => {
        dispatch(actions.setAlerts(
            {
                key: alerts.uploadAudio,
                value: { open: true, props: id, }
            }))
    }

    const fields = [
        nameField.field,
        {
            field: 'assessment_count',
            headerName: "מספר שלבים",
            width: 100,
            headerAlign: 'center',
            align: 'center',
            // renderCell: AssessmentStatusCell,
            // valueGetter: (value) => `${value.row || ""}`,
        },
        {
            field: 'status',
            headerName: "סטטוס",
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: AssessmentStatusCell,
            valueGetter: (value) => `${value.row || ""}`,
        },
        gradeOfAllocation,
        {
            field: 'file_uploaded_date',
            headerName: "עדכון ביצוע",
            headerAlign: 'center',
            width: 150,
            align: "center",
            valueGetter: ({ row }) =>
                timeAgoWithOutSeconds(
                    row.assessment_group.reduce((latestDate, assessment) => {
                        const assessmentDate = new Date(assessment.file_uploaded_date);
                        return assessmentDate > latestDate ? assessmentDate : latestDate;
                    }, null))
                || ""
        },
        {
            field: 'record',
            type: 'actions',
            headerName: "ביצוע",
            width: 100,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'actions',
            getActions: ({ row }) => {
                const isBrokenFile = row.status === statuses.brokenFile
                // const disabledRow = !isBrokenFile && row.audio_file_url

                const { assessment_group } = row
                const disabledRow = !(assessment_group.find((ass) => !ass.audio_file_url || ass.status === statuses.brokenFile))

                return [
                    <IconButtonGroup
                        color="bg"
                        tooltip="לביצוע"
                        selected={!disabledRow}
                        icon={disabledRow ? GoDisabledIcon : GoIcon}
                        action={() => {
                            goRecord(row)
                        }}
                        disabled={disabledRow}
                    />
                ];
            }
        },
        {
            field: 'upload',
            type: 'actions',
            headerName: "העלאת אודיו",
            width: 100,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'actions',
            feature: "upload-audio",
            role: "create",
            getActions: ({ row }) => {
                const { assessment_group } = row

                return [
                    <IconButtonGroup
                        color="bg"
                        tooltip="להעלאת אודיו"
                        selected={true}
                        icon={UploadFileIcon}
                        action={() => {
                            openUploadAudioPopup(row.id)
                        }}
                    />
                ];
            }
        },
        playField
    ]


    return fields
}

export default useFields