import React, { useEffect } from 'react'
import { Discount, SubSoon, Soon,WrapSubLabel, Feature, CardFooter, WrapPrices, WrapContentPadding, Description, WrapFeatures, WrapPrice, SubPrice, Price, CardContent, WrapCards, SubTitle, Title, CardHeader, WrapPriceContent, WrapSoon } from './Style'
import { Card, CardTitle } from './Style'
import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { CheckCircleRounded } from '@mui/icons-material'
import { Button } from 'components/Form'
import { Space } from 'styles/GlobalStyle'
import { paymentHooks } from '.'

const Prices = (props) => {
    const { disabled, height, soon, button, title, index, registerForm, registerLoading, discount, features, subTitle, description, price, subPrice } = props

    const paymentBtn = paymentHooks.usePaymentBtn()
    const { paymentLinks } = paymentHooks.constants

    const labelBtn = button.label(price, 1)

    return (
        <Card height={height} key={index} $disabled={disabled}>
            <CardHeader>
                <WrapSoon soon={soon}>
                    <Soon>{soon}</Soon>
                </WrapSoon>
                <CardTitle>
                    {title}
                </CardTitle>
                {subTitle}
                <Space />
            </CardHeader>
            <CardContent>
                <WrapContentPadding>
                    <WrapPriceContent>
                        <WrapPrice>
                            <Price>
                                {`₪${price}`}
                            </Price>
                            <Discount>
                                {discount?.label}
                            </Discount>
                        </WrapPrice>
                        <WrapSubLabel>
                            <SubSoon>
                                {discount?.subLabel}
                            </SubSoon>
                            <SubPrice>
                                {subPrice}
                            </SubPrice>
                        </WrapSubLabel>
                    </WrapPriceContent>


                    <Description>
                        {description}
                    </Description>
                </WrapContentPadding>
                <WrapFeatures>
                    {features.map(feature =>
                        <>
                            <MenuItem sx={{ height: "25px" }}>
                                <ListItemIcon>
                                    <CheckCircleRounded color="primary2" />
                                </ListItemIcon>
                                <ListItemText>
                                    {feature.label}
                                </ListItemText>
                            </MenuItem>
                            <Divider />
                        </>
                    )}
                </WrapFeatures>
                <CardFooter $disabled={disabled}>
                    <Button
                        {...paymentBtn}
                        label={labelBtn}
                        action={() => paymentBtn.action({ ...props, link: paymentLinks[registerForm?.balance?.id || 1] })}
                        loading={registerLoading}
                    />
                </CardFooter>
            </CardContent>
        </Card>
    )
}

export default Prices