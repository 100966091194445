/**
 * This file contains all application's style relative to fonts
 */
// import { StyleSheet } from 'react-native';
import { ThemeVariables } from 'styles/theme';
import { dark } from './theme';

export default function ({ FontSize, Colors }: ThemeVariables) {
    return {
        //    StyleSheet.create({
        textTiny: {
            fontSize: FontSize.tiny,
            color: Colors.textGray400,
        },
        textSmallItalics: {
            fontSize: FontSize.small,
            color: Colors.textGray400,
            fontStyle: 'italic',
        },
        textSmall: {
            fontSize: FontSize.small,
            color: Colors.textGray400,
        },
        textRegular: {
            fontSize: FontSize.regular,
        },
        fontsizeRegular: {
            fontSize: FontSize.regular,
        },
        textLarge: {
            fontSize: FontSize.large,
            color: Colors.textGray400,
        },
        textBold: {
            fontWeight: 'bold',
        },
        textUppercase: {
            textTransform: 'uppercase',
        },
        titleTiny: {
            fontSize: FontSize.small * 1.2,
            fontWeight: 'bold',
            color: Colors.textGray400,
        },
        titleXxs: {
            fontSize: FontSize.small * 1.1,
            fontWeight: 'bold',
            color: Colors.textGray400,
        },
        titleSmall: {
            fontSize: FontSize.small * 1.5,
            fontWeight: 'bold',
            color: Colors.textGray400,
        },
        titleRegular: {
            fontSize: FontSize.regular * 2,
            fontWeight: 'bold',
            color: Colors.textGray800,
        },
        titleLarge: {
            fontSize: FontSize.large * 2,
            fontWeight: 'bold',
            color: Colors.textGray800,
        },
        textCenter: {
            textAlign: 'center',
        },
        textJustify: {
            textAlign: 'justify',
        },
        textLeft: {
            textAlign: 'left',
        },
        textRight: {
            textAlign: 'right',
        },
        textError: {
            color: Colors.error,
        },
        textSuccess: {
            color: Colors.success,
        },
        textPrimary: {
            color: Colors.primary,
        },
        textLight: {
            color: Colors.textGray200,
        },
        textDark: {
            color: dark,
        },
        textWhite: {
            color: Colors.white,
        },
        textLobster: {
            fontFamily: 'lobster',
            fontWeight: 'normal',
        },
    }
    //   });
}