import styled from "styled-components";
import { Box, Drawer, drawerClasses } from "@mui/material";
import { Center, Hover } from "styles/GlobalCss";
import { styled as muiStyled } from '@mui/material/styles';

const WordWithFrequency = styled.div`
border:${props => `2px solid ${props.color}`};
border-radius: 6px;
${Hover};
`

const AssessmentCount = styled.div`
height:30px;
backgroun
`

const WrapStatus = styled.div`
display:${props => props.hide && 'none'};
color:${props => props.color};
`

const DrawerWordFrequency = muiStyled(Drawer)(({ theme, $systemUI }) => ({
    [`& .${drawerClasses.paper}, & .${drawerClasses.root}`]: {
    },
    '.drawer-content': {
        width: "525px",
        minHeight: "50%"
    },
    '.custom-footer': {
        display: "flex",
        columnGap: '4px',
        ...Center
    }
}));

const WrapIDS = muiStyled(Box)(({ theme, $systemUI, len, index }) => ({
    display: "flex",
    columnGap: 5,
    alignItems: "center",
    '.next-icon': {
        opacity: !index && 0.5,
        pointerEvents: !index && "none"
    },
    '.prev-icon': {
        opacity: index === len - 1 && 0.5,
        pointerEvents: index === len - 1 && "none"
    }
}));

export { WrapIDS, DrawerWordFrequency, WrapStatus, WordWithFrequency, AssessmentCount }