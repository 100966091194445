import { useSelector } from "react-redux";
import { getDecodedFilenameFromUrl } from "utils/formatter"


const useDownload = () => {
    const { assessment } = useSelector(state => state.assessment)

    const downloadAudio = async () => {
        if (assessment.audio_file) {
            const downloadLink = document.createElement('a');
            downloadLink.href = assessment.audio_file
            downloadLink.download = `${getDecodedFilenameFromUrl(assessment.audio_file_url)}.wav`;
            downloadLink.click();
        }
    };

    return downloadAudio
}
export default useDownload




    // const downloadAudio = async () => {
    //     if (assessment.audio_file_url) {
    //         fetch(`${assessment.audio_file_url}/`)
    //             .then(response => response.blob())
    //             .then(blob => {
    //                 const url = window.URL.createObjectURL(new Blob([blob]));
    //                 const link = document.createElement('a');
    //                 link.href = url;
    //                 link.download = "audio.mp3"
    //                 link.click();
    //             })
    //             .catch(error => console.error('Error downloading file:', error));
    //     }
    // };