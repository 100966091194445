import React, { useContext, useEffect, useState } from 'react'
import { RateAndAccuracyItem, RateAndAccuracyLabel, GraphDisplay, WrapAccordion } from './Style'
import { GradeGraph, ratingHooks } from '.'
import { useTheme } from 'styles'
import { useSelector } from 'react-redux'
import { isEmpty, keyBy, set } from 'lodash'
import { ReactComponent as ChildIcon } from 'assets/icons/Child.svg';
import { ReactComponent as ChildrenIcon } from 'assets/icons/Children.svg';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Divider, SvgIcon } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { IconButtons } from 'components/Form'
import { RatingContext } from './RatingContext'

const RateAndAccuracy = () => {
    const { viewGraph, setCurrentSection, setViewGraph, scoreRating, currentGraph, currentSectionRef, setCurrentGraph } = useContext(RatingContext)
    const { rating } = useSelector(state => state.rating)

    const [previousStats, setPreviousStats] = useState(null);

    const { Layout, Gutters } = useTheme()

    const rateAndAccuracyItems = ratingHooks.useRateAndAccuracyItems()
    const viewGraphBtns = ratingHooks.useViewGraphBtns()
    const viewGraphKeys = keyBy(viewGraphBtns, "key")
    const rateAndAccuracyKeys = keyBy(rateAndAccuracyItems, "type")

    const [expand, setExpand] = useState(new Array(rateAndAccuracyItems.length).fill(false))

    const setExpandItem = (index) => {
        let nExpand = [...expand]
        nExpand[index] = !expand[index]
        setExpand(nExpand)
    }

    const updateViewGraph = (e, key, val, item, index) => {
        setViewGraph({ ...viewGraph, [key]: val })
        if (item.stats) {
            setPreviousStats(item.stats);
        }

        rateAndAccuracyKeys[key]?.refetch(viewGraphKeys[val]?.params)

        if (!expand[index])
            setExpandItem(index)

    }

    return (
        <div>
            {rateAndAccuracyItems.map((item, index) =>
                <>
                    <WrapAccordion expanded={expand[index]}>
                        <AccordionSummary
                            sx={{
                                ...Layout.fullHeight,
                                pointerEvents: !item.expand && "none"
                            }}
                            expandIcon={
                                item.expand &&
                                <ExpandMore
                                    onClick={() => setExpandItem(index)}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <div style={{ ...Layout.rowHCenter, ...Layout.justifyContentBetween, ...Layout.fullWidth }}>
                                <div style={{ ...Layout.rowHCenter, ...Layout.xlcGap }}>
                                    {item.label && <RateAndAccuracyLabel>{item.label}</RateAndAccuracyLabel>}
                                    {isEmpty(rating) ||
                                        <div className='tab-title'>
                                            <item.text />
                                        </div>}
                                </div>
                                <div style={{ ...Layout.rowHCenter, ...Layout.mcGap, ...Gutters.tinyLPadding }}>
                                    {item.showGraphBtns &&
                                        <React.Fragment>
                                            <IconButtons
                                                key={item.type}
                                                selected={viewGraph[item.type]}
                                                setSelected={(e, val) => {
                                                    updateViewGraph(e, item.type, val, item, index)
                                                }}
                                                btns={viewGraphBtns}
                                            />
                                        </React.Fragment>}
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <GradeGraph scoreRating={scoreRating} disabled={!item.stats} stats={item.stats || previousStats} percent={item.percent} type={item.type} showAllChildren={viewGraph[item.type]} />
                        </AccordionDetails>
                    </WrapAccordion>
                </>
            )}
        </div>
    )
}

export default RateAndAccuracy