import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Scroll } from "styles/GlobalCss";
import { thirdly3, white } from "styles/theme";

const WrapDataGrid = styled.div`
display: flex;
flex-direction: column;
height: 100%;

.action_btn{
padding:5px 5px !important;
font-size:14px !important;
height:27px !important;
direction:ltr !important;
`

const WrapGridToolbarQuickFilter = muiStyled(GridToolbarQuickFilter)(({ theme }) => (
    {
        '& .MuiInputBase-root': {
            columnGap: '10px'
        }
    }))


const WrapGridToolbarContainer = styled(GridToolbarContainer)`
display:flex;
justify-content:space-between;
`

const WrapGridToolbar = styled(GridToolbar)`
direction:ltr !important;
`

const WrapDataGridTable = styled(DataGrid)`
flex-grow: 1;
background:${white} !important;
border: 1px solid ${thirdly3} !important;

${Scroll};
 overflow: auto !important;

.MuiDataGrid-main{
 overflow: auto;
}

.MuiDataGrid-footerContainer{
direction:ltr !important;
}

.MuiDataGrid-footerContainer{
display:${props => props.hidePagination && 'none !important'};
}

.MuiDataGrid-columnHeader:focus .MuiDataGrid-cell:focus{
// .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

`

const WrapHeader = styled.div`
width:100%;
height: 70px;
flex-shrink: 0;
align-items:center;
display:flex;
`


const Header = styled.div`
width:100%;
display: flex;
column-gap: 10px;
justify-content: flex-end;
`

const StyledGridOverlay = muiStyled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

export { WrapDataGridTable, WrapGridToolbarQuickFilter, WrapGridToolbarContainer, WrapGridToolbar, StyledGridOverlay, WrapDataGrid, WrapHeader, Header }