import { useDispatch } from "react-redux"
import { actions } from 'store'
import { MarkingWordsAssessmentVM, WordVM } from "models/VM";
import { useParams } from "react-router-dom";

const AssessmentCrud = () => {
    const dispatch = useDispatch()

    const params = useParams()

    const getWords = async () => {
        try {
            let nAssessment = await dispatch(actions.getWords(params.assessmentID))
            const mWords = []
            nAssessment.words.forEach((word, index) => mWords.push(new WordVM({ assessment: nAssessment, ...word }, index)))

            dispatch(actions.setWords(mWords))
            nAssessment = new MarkingWordsAssessmentVM(nAssessment, nAssessment.words)
            dispatch(actions.updateAssessment(nAssessment))
        }
        catch {
            console.log("getWords error")
        }
    }

    const cleanData = () => {
        dispatch(actions.setWords(null))
    }

    const init = async () => {
        getWords()
    }


    return {
        init,
        cleanData,
    }
}

export default AssessmentCrud